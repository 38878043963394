import _ from 'lodash';
import { GET_SIGHTINGS, GET_SIGHTING_CALL, GET_SIGHTING, SET_ALT_ID, ActionTypes, GET_ALL_ENGINE_PICTURES } from '../actions';

const defaultState = {
  totalSightingsByLocation: {},
  onThisDay: null,
  sightingsForEngine: [],
  currentEngineHasAltId: false,
  picturesForEngine: null,
  sightingsByLocation: {
    all: {},
  },
};

const addPage = (object, page) => {
  const ret = {};
  Object.keys(object).forEach(key => {
    ret[object[key].id] = _.extend({ page: page + 1 }, object[key]);
  });
  return ret;
};

export default (state = defaultState, action) => {
  const newTotalSightingsByLocation = { ...state.totalSightingsByLocation };
  let locationId = action && action.payload && action.payload.location ? action.payload.location : 'all';
  if (locationId === null || locationId === '') {
    locationId = 'all';
  }

  const { currentEngineHasAltId } = state;

  const newSightingsByLocation = { ...state.sightingsByLocation };
  const mapped = action.payload && action.payload.trains ? _.mapKeys(action.payload.trains, 'id') : null;
  switch (action.type) {
    case ActionTypes.GotOnThisDay:
      return {
        ...state,
        onThisDay: action.payload,
      }
    case ActionTypes.SaveBlurHashSuccess:
      Object.keys(state.sightingsByLocation[locationId]).forEach(key => {
        const s = state.sightingsByLocation[locationId][key];
        s && s.pictures && s.pictures.forEach((pic) => {
          if (pic.id === parseInt(action.payload.id)) {
            pic.blurhash = action.payload.hash;
          }
        });
      });
      return state;
    case SET_ALT_ID:
      return {
        onThisDay: state.onThisDay,
        totalSightingsByLocation: newTotalSightingsByLocation,
        sightingsByLocation: newSightingsByLocation,
        sightingsForEngine: state.sightingsForEngine,
        currentEngineHasAltId: true,
      };
    case GET_ALL_ENGINE_PICTURES:
      return {
        ...state,
        picturesForEngine: action.payload,
      }
    case GET_SIGHTING:
      newSightingsByLocation.all[action.payload.id] = action.payload;
      return {
        onThisDay: state.onThisDay,
        totalSightingsByLocation: newTotalSightingsByLocation,
        sightingsByLocation: newSightingsByLocation,
        sightingsForEngine: state.sightingsForEngine,
        currentEngineHasAltId,
      };
    case GET_SIGHTING_CALL:
      newSightingsByLocation.all[action.payload] = 'loading';
      return {
        onThisDay: state.onThisDay,
        totalSightingsByLocation: newTotalSightingsByLocation,
        sightingsByLocation: newSightingsByLocation,
        sightingsForEngine: state.sightingsForEngine,
        currentEngineHasAltId,
      };
    case GET_SIGHTINGS:
      newTotalSightingsByLocation[locationId] = action.payload.count;
      if (!newSightingsByLocation[locationId]) {
        newSightingsByLocation[locationId] = {};
      }

      newSightingsByLocation[locationId] = {
        ...newSightingsByLocation[locationId],
        ...addPage(mapped, action.payload.page),
      };

      if (action.payload.trains.length > 0) {
        return {
          onThisDay: state.onThisDay,
          totalSightingsByLocation: newTotalSightingsByLocation,
          sightingsByLocation: newSightingsByLocation,
          sightingsForEngine: action.payload.forEngine ? action.payload.trains : null,
          currentEngineHasAltId: action.payload.alt_id !== '' && action.payload.alt_id !== 'no',
        };
      }
      return state;
    // break;
    default:
      return state;
    // break;
  }
};
