import { SET_COLOR_MODE } from '../actions';
import IAction from '../interfaces/IAction';

const defaultState = {
  colorMode: 'dark',
};

export default (state = defaultState, action: IAction) => {
  switch (action.type) {
    case 'CREATE_BLUR_HASH_SUCCESS':
      const { imageData, id } = action.payload;
      return {
        ...state,
        imageData,
        id,
      };
    case SET_COLOR_MODE:
      return {
        colorMode: action.payload,
      };
    // break;
    default:
      return state;
    // break;
  }
};
