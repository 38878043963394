import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Utils from './Utils';
import SightingTime from './SightingTime';
import { getSighting } from '../actions';
import IAppState from '../interfaces/IAppState';
import IAppSettings from '../interfaces/IAppSettings';

type SightingProps = {
  locationSlug?: string;
  className: string;
  showLocation: boolean;
  engineCount: boolean;
  sightingId: number;
  onSightingLoad: Function;
  image: boolean;
  doLoad: boolean;
  sightingObj: object;
  sightingLoaded: boolean;
  noPics: boolean;
};
const Sighting: React.FC<SightingProps> = (props) => {
  const { showLocation, className, image, engineCount, sightingId, locationSlug, onSightingLoad, noPics, sightingObj  } = props;
  const [state, setState] = useState({
    imageLoaded: false,
    loading: false,
  });
  const settings: IAppSettings = useSelector((appState: IAppState) => appState.settings);
  const sightings = useSelector((appState: IAppState) => appState.sightings);
  const { colorMode } = settings;
  const dispatch = useDispatch();
  let sighting = sightings.sightingsByLocation.all[sightingId];
  let locSlug = '';
  const locations = useSelector((appState: IAppState) => appState.locations);
  let location = sighting ? locations[sighting.location] : null;
  if (locationSlug && !location) {
    locSlug = locationSlug.replace('/', '');
    location = Utils.getLocationFromSlug(locSlug, locations);
  }
  const getIt = () => {
    const { loading } = state;
    const { sightingId, doLoad, image, sightingLoaded } = props;
    if (!loading && doLoad && image && !sightingLoaded) {
      setState({
        ...state,
        loading: true,
      });
      dispatch(getSighting(sightingId));
    }
  };
  if (!sighting || (!sighting.pictures && noPics === false)) {
    try {
      sighting = sightings.sightingsByLocation[location.id][sightingId];
    } catch (e) {
      // no sighting
    }
    getIt();
  }

  const history = useHistory();

  const goToSighting = () => {
    // const { sighting } = props;
    history.push(`/view-sighting/${sighting ? sighting.id : ''}${sightingObj ? sightingObj.id : ''}`);
  };

  const { imageLoaded } = state;

  let loc = '';
  if (showLocation) {
    if (location) {
      loc = location.name;
    }
  }
  let image2: HTMLImageElement = null;

  if (sighting && sighting.pictures && sighting.pictures[0] && sighting.pictures[0]) {
    image2 = (
      <img
        onLoad={(e) => {
          e.currentTarget.classList.add('done');
          onSightingLoad();
          setState((oldState) => {
            return {
              ...oldState,
              imageLoaded: true,
            };
          });
        }}
        alt=""
        srcSet={Utils.getSrcSet(sighting.pictures[0])}
      />
    );
  }

  return (
    <article className={`${className} ${className}--${colorMode}`} data-timestamp={sighting ? sighting.timestamp : ''}>
      {image && (
        <div className="engine-sighting__img-wrap engine-sighting__img-wrap--loading">
          <div className="engine-sighting__img-wrap-inner engine-sighting__img-wrap-inner--loading">
            {image2}
            {!imageLoaded && (
              <div className="engine-sighting__loader engine-sighting__loader--loading">
                <div />
                <div />
                <div />
                <div />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="sighting__copy-wrap">
        <>
          {sighting && sighting.timestamp && sighting.timestamp !== '0000-00-00 00:00:00' && <SightingTime string={sighting.timestamp} />}
          {sighting && (sighting.timestamp === null || sighting.timestamp === '0000-00-00 00:00:00') && (
            <SightingTime
              ts={sighting.timestamp}
              string={sighting.timestamp}
              length={sighting && sighting.pictures && sighting.pictures.length}
            />
          )}
          {sightingObj && sightingObj.local_time && (
            <div>{new Date(sightingObj.local_time).toLocaleDateString()}<br/>
            {new Date(sightingObj.local_time).toLocaleTimeString()} Time Where Picture Was Taken<br/>
            {new Date(sightingObj.local_time).toLocaleTimeString('en-US', { timeZone: sightingObj.timezone})} Time Where I Am<br/></div>
          )}
       
          <a
            href={`/view-sightings/${Utils.slugify(loc)}`}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/view-sightings/${Utils.slugify(loc)}`);
            }}
          >
            {loc}
          </a>
          {engineCount && sighting ? <span>Engines: {sighting.engines && sighting.engines.length}</span> : ''}
        </>
      </div>
      <div className="sighting__button-container">
        {(sighting || sightingObj) && (
          <button
            className={`sighting__button ${className}-button`}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              goToSighting();
              return false;
            }}
          >
            View: {sighting && sighting.id}{sightingObj && sightingObj.id}
          </button>
        )}
      </div>
    </article>
  );
};

Sighting.defaultProps = {
  className: '',
  image: false,
  sightingLoaded: false,
  showLocation: false,
  engineCount: false,
  doLoad: false,
  locationSlug: null,
  onSightingLoad: null,
};

Sighting.propTypes = {
  locationSlug: PropTypes.string,
  engineCount: PropTypes.bool,
  doLoad: PropTypes.bool,
  className: PropTypes.string,
  image: PropTypes.bool,
  sightingLoaded: PropTypes.bool,
  showLocation: PropTypes.bool,
  onSightingLoad: PropTypes.func,
  sightingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default Sighting;
