import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

type EngineTagProps = {
  onClick?: Function;
  onMouseOverFunc?: Function;
  onMouseOutFunc?: Function;
  num: int;
  road: string;
  short: boolean;
  noLink?: boolean;
  big?: boolean;
};
const EngineTag: React.FC<EngineTagProps> = ({
  road,
  short,
  onMouseOverFunc,
  onMouseOutFunc,
  big,
  num,
  noLink,
  onClick,
}) => {
  const history = useHistory();
  const goToEngine = () => {
    history.push(`/view-engine/${road.toLowerCase()}/${num && num.toString().replace('&nbsp;', '')}`);
  };

  const onClickFunc = (e) => {
    if (noLink) {
      onClick(road, num);
      e.preventDefault();
      return false;
    }
    goToEngine();
  };

  const originalNum = num.toString();
  if (num.toString().length < 4) {
    let toAdd = '';
    const spacesToAdd = 4 - originalNum.length;
    for (let i = 0; i < spacesToAdd; i += 1) {
      toAdd += '&nbsp;';
    }
    num = toAdd + originalNum;
  }

  return short ? (
    <a
      href={`/view-engine/${road.toLowerCase()}/${num && num.toString().replace('&nbsp;', '')}`}
      aria-label="View Engine"
      type="button"
      className={`roadname roadname--${big ? 'big' : 'small'} roadname--${
        road ? road.replace(new RegExp('&nbsp;', 'g'), '').toLowerCase() : ''
      }`}
      dangerouslySetInnerHTML={{ __html: road + num }}
      onMouseOver={onMouseOverFunc}
      onFocus={onMouseOverFunc}
      onMouseOut={onMouseOutFunc}
      onBlur={onMouseOutFunc}
      onClick={onClickFunc}
    />
  ) : (
    <>
      <span
        className={`roadname roadname--small roadname--${
          road ? road.replace(new RegExp('&nbsp;', 'g'), '').toLowerCase() : ''
        }`}
        dangerouslySetInnerHTML={{ __html: road + num }}
        onMouseOver={onMouseOverFunc}
        onFocus={onMouseOverFunc}
        onMouseOut={onMouseOutFunc}
        onBlur={onMouseOutFunc}
      />
      <button type="button" className="plain-button" onClick={onClickFunc}>
        View Engine
      </button>
    </>
  );
};

EngineTag.defaultProps = {
  onMouseOverFunc: null,
  onMouseOutFunc: null,
  short: false,
  noLink: false,
  onClick: null,
  big: false,
};

EngineTag.propTypes = {
  onClick: PropTypes.func,
  onMouseOverFunc: PropTypes.func,
  onMouseOutFunc: PropTypes.func,
  num: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  road: PropTypes.string.isRequired,
  short: PropTypes.bool,
  noLink: PropTypes.bool,
  big: PropTypes.bool,
};

export default EngineTag;
