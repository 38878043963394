import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SingleImageInput from './SingleImageInput';
import { getRecentlySeenEngines } from '../actions';
import EngineTag from './EngineTag';
import Utils from './Utils';

class AddImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: 1,
      pictureInputs: [],
      allValid: false,
      imageData: [],
      setSightingManually: false,
      forceNewSighting: false,
      possibleSightings: [],
      busy: false,
      engineData: {
        0: {
          0: {},
        },
      },
    };
    this.dataChange = this.dataChange.bind(this);
    this.loadFrameCount = 0;
    this.uploadDone = this.uploadDone.bind(this);
    this.loadIconInterval = null;
    this.loadFrameMax = 7;
    this.gotPossibleManualSightings = this.gotPossibleManualSightings.bind(this);
    this.addOne = this.addOne.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.removeOne = this.removeOne.bind(this);
    this.passUpImage = this.passUpImage.bind(this);
    this.isSingleImageValid = this.isSingleImageValid.bind(this);
    this.setManualSighting = this.setManualSighting.bind(this);
    this.doIHaveAnImage = this.doIHaveAnImage.bind(this);
    this.forceNewSighting = this.forceNewSighting.bind(this);
    this.removeOneEngine = this.removeOneEngine.bind(this);
  }

  componentDidMount() {
    document.title = 'TrainTracker: Add Image';
    this.props.getRecentlySeenEngines();
  }

  setManualSighting() {
    this.setState({
      setSightingManually: !this.setSightingManually,
    });
  }

  removeOneEngine(name) {
    const engineData = { ...this.state.engineData };
    const imageIds = Object.keys(engineData[name]);
    let toDelete = -1;
    imageIds.forEach(id => {
      if (parseInt(id) > toDelete) {
        toDelete = id;
      }
    });
    delete engineData[name][toDelete];
    this.setState({ engineData });
  }

  stopLoadIcon() {
    clearInterval(this.loadIconInterval);
    document.querySelector('link[rel="shortcut icon"]').setAttribute('href', '/favicon.ico');
  }

  updateLoadIcon() {
    const path = `assets/frame_${this.loadFrameCount}_delay-0.1s.png`;
    document.querySelector('link[rel="shortcut icon"]').setAttribute('href', path);
    document.querySelector('link[rel="shortcut icon"]').setAttribute('type', 'image/gif');
    this.loadFrameCount += 1;
    if (this.loadFrameCount >= this.loadFrameMax) {
      this.loadFrameCount = 0;
    }
  }

  startLoadIcon() {
    this.loadIconInterval = setInterval(() => {
      this.updateLoadIcon();
    }, 250);
  }

  doIHaveAnImage() {
    const { setSightingManually } = this.state;

    if (setSightingManually) {
      const fd = new FormData(document.getElementById('sighting_form'));
      const oReq = new XMLHttpRequest();
      // oReq.setRequestHeader('Content-Type', 'application/upload');
      // oReq.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      oReq.open('POST', 'get_possible_manual_sightings.php', true);
      oReq.addEventListener('load', this.gotPossibleManualSightings);
      oReq.send(fd);
      return false;
    }
  }

  gotPossibleManualSightings(e) {
    const oReq = e.currentTarget;
    if (oReq.status === 200 && oReq.readyState === 4) {
      this.setState({
        possibleSightings: JSON.parse(this.response).sightings,
      });
    }
  }

  forceNewSighting() {
    const { forceNewSighting } = this.state;
    this.setState({
      forceNewSighting: !forceNewSighting,
    });
  }

  dataChange(data, type, indexes) {
    const split = indexes.split('-');
    const imageIndex = parseInt(split[0]);
    const engineIndex = parseInt(split[1]);

    const engineData = { ...this.state.engineData };
    if (!engineData[imageIndex]) {
      engineData[imageIndex] = {};
    }
    if (!engineData[imageIndex][engineIndex]) {
      engineData[imageIndex][engineIndex] = {};
    }
    engineData[imageIndex][engineIndex][`${type === 'road' ? 'road' : 'num'}`] = data;
    console.dir(engineData);
    this.setState({ engineData });
  }

  passUpImage(nameval, val) {
    const { imageData } = this.state;
    const siis = { ...imageData };
    siis[nameval] = val;
    this.setState({
      imageData: siis,
    });
  }

  addOne() {
    const { pictures } = this.state;
    this.setState({
      pictures: pictures + 1,
      allValid: false,
    });
  }

  isSingleImageValid(nameval, val) {
    console.log('isSingleImageValud?');
    console.log(`${nameval}-${val}`);
    const { pictures, pictureInputs, engineData } = this.state;

    const siis = { ...pictureInputs };
    siis[nameval] = val;
    this.setState({
      pictureInputs: siis,
    });
    let validCount = 0;
    Object.keys(siis).forEach(key => {
      if (siis[key] === true) {
        validCount += 1;
      } else {
        let valid = true;
        Object.keys(engineData[key]).forEach(singleEngineKey => {
          const eng = engineData[key][singleEngineKey];
          const validEngine = Utils.validEngine(eng.road, eng.num);
          if (!validEngine) {
            valid = false;
          }
        });
        if (valid) {
          validCount += 1;
        }
      }
    });
    let valid = true;
    if (validCount < pictures) {
      valid = false;
    }
    this.setState({
      allValid: valid,
    });
  }

  removeOne() {
    const { pictures } = this.state;
    this.setState({
      pictures: pictures - 1,
    });
  }

  handleSubmit(e) {
    const { imageData } = this.state;
    this.setState({
      busy: true,
    });
    e.preventDefault();
    const data = {
      images: {},
    };
    const fd = new FormData(e.currentTarget);
    let count = 0;
    Object.keys(e.currentTarget.elements).forEach(inputElement => {
      const el = e.currentTarget.elements[inputElement];
      const inputName = el.name ? el.name : '';
      if (inputName.indexOf('image') >= 0 || inputName.indexOf('engine') >= 0 || inputName.indexOf('road') >= 0) {
        const imageNumber = inputName.split('-')[1];
        if (!data.images[imageNumber]) {
          count += 1;
          data.images[imageNumber] = {};
          data.images[imageNumber].engines = {};
        }
        if (inputName.indexOf('image') < 0) {
          const roadAndEngineNumber = inputName.split('-')[2];
          if (!data.images[imageNumber].engines[roadAndEngineNumber]) {
            data.images[imageNumber].engines[roadAndEngineNumber] = {};
          }
          if (inputName.indexOf('engine') >= 0) {
            data.images[imageNumber].engines[roadAndEngineNumber].engine = el.value;
          } else {
            data.images[imageNumber].engines[roadAndEngineNumber].road = el.value;
          }
        } else {
          // this is the image
          data.images[imageNumber].image = el.value;
          data.images[imageNumber].imageData = encodeURIComponent(imageData[imageNumber]);
          // maybe js encodeURIComponent
        }
      }
    });
    fd.append('images', count);
    //fd.append('json', JSON.stringify(data));
    const oReq = new XMLHttpRequest();
    oReq.open('POST', '/api/submit-sighting', true);
    // oReq.addEventListener('progress', this.uploadProgress);
    oReq.addEventListener('load', this.uploadDone);
    this.startLoadIcon();
    oReq.send(fd);
    return false;
  }

  uploadDone(e) {
    const oReq = e.currentTarget;
    document.querySelector('link[rel="shortcut icon"]').setAttribute('href', '');
    document.querySelector('link[rel="shortcut icon"]').removeAttribute('type');
    this.stopLoadIcon();
    if (oReq.status === 200) {
      const res = JSON.parse(oReq.response);
      console.log(`Uploaded!: ${res.sightingId}`);

      this.setState({
        pictures: 1,
        pictureInputs: [],
        allValid: false,
        imageData: [],
        busy: false,
        imageAddedToSightingId: res.id,
      });
    } else {
      console.log(`Error ${oReq.status} occurred when trying to upload your file.`);
      // todo - allow re-upload?
      console.dir(e);
    }
  }

  // uploadProgress(oEvent) {
  //   if (oEvent.lengthComputable) {
  //     const percentComplete = oEvent.loaded / oEvent.total * 100;
  //   }
  // }

  render() {
    const { pictures, imageData, imageAddedToSightingId, engineData } = this.state;
    const { roads, engines, recentlySeenEngines } = this.props;
    const rows = [];
    for (let i = 0; i < pictures; i += 1) {
      if (i > 0) {
        rows.push(
          <div key={`sii-wrap${i}`}>
            <SingleImageInput
              setValidity={this.isSingleImageValid}
              passUpImage={this.passUpImage}
              imageData={imageData[i]}
              dataChange={this.dataChange}
              removeEngine={this.removeOneEngine}
              name={i}
              key={i}
              roads={roads}
              engines={engines}
              engineData={engineData[i]}
            />
            <button type="button" onClick={this.removeOne}>
              Remove
            </button>
          </div>
        );
      } else {
        rows.push(
          <SingleImageInput
            dataChange={this.dataChange}
            setValidity={this.isSingleImageValid}
            name={i}
            key={i}
            removeEngine={this.removeOneEngine}
            imageData={imageData[i]}
            passUpImage={this.passUpImage}
            doIHaveAnImage={this.doIHaveAnImage}
            roads={roads}
            engineData={engineData[i]}
            engines={engines}
          />
        );
      }
    }
    let manualSightingSelection = '';
    const { setSightingManually, possibleSightings, allValid, busy } = this.state;
    if (setSightingManually && possibleSightings.length > 0) {
      const opts = [];
      possibleSightings.forEach(key => {
        const ds = new Date(1000 * key.ts);
        opts.push(<option value={key.sighting_id}>{ds.toString()}</option>);
      });
      manualSightingSelection = (
        <select id="sighting-id" name="sighting-id">
          {opts}
        </select>
      );
    }

    const buttonStatus = allValid && !busy ? '' : 'disabled';
    return (
      <div>
        <h2>Add Sighting & Images</h2>
        <form onSubmit={this.handleSubmit} method="post" encType="multipart/form-data" id="sighting_form">
          <h3>Recently Seen Engines</h3>
          <ul>
            {Object.keys(roads).length > 0 &&
              recentlySeenEngines.map(eng => {
                return (
                  <EngineTag
                    num={eng.num}
                    road={roads.filter(r => r.id === eng.road)[0].short_name}
                    short
                    noLink
                    key={`${eng.road}-${eng.num}`}
                    onClick={(road, num) => {
                      const roadId = Utils.getRoadId(road, roads);
                      const engineDataCopy = { ...engineData };
                      let gotOne = false;
                      Object.keys(engineData).forEach(imageIndex => {
                        const image = engineData[imageIndex];
                        Object.keys(image).forEach(engineIndex => {
                          const engine = image[engineIndex];
                          if (!Utils.validEngine(engine.road, engine.num) && !gotOne) {
                            engineDataCopy[imageIndex][engineIndex].num = num;
                            engineDataCopy[imageIndex][engineIndex].road = roadId;
                            gotOne = true;
                          }
                        });
                      });

                      if (!gotOne) {
                        const imageCount = Object.keys(engineDataCopy).length;
                        const imageInsertIndex = imageCount - 1;
                        const engineCount = Object.keys(engineDataCopy[imageInsertIndex]).length;
                        const engineInsertIndex = engineCount;
                        engineDataCopy[imageInsertIndex][engineInsertIndex] = {
                          num,
                          road: roadId,
                        };
                      }

                      this.setState({
                        engineData: engineDataCopy,
                      });

                      return false;
                    }}
                  />
                );
              })}
          </ul>
          <label htmlFor="set-sighting-manually">
            <input
              onChange={this.setManualSighting}
              type="checkbox"
              name="set-sighting-id-manually"
              id="set-sighting-manually"
            />
            Set Sighting ID Manually
          </label>
          {manualSightingSelection}
          <label htmlFor="force-new-sighting">
            <input onChange={this.forceNewSighting} type="checkbox" name="force-new-sighting" id="force-new-sighting" />
            Force New Sighting Sighting
          </label>
          {rows}
          <div className="action-buttons">
            <div className="action-buttons__content">
              <button type="button" className="action-buttons__button" onClick={this.addOne}>
                Add Image
              </button>
              <input className="action-buttons__button" disabled={buttonStatus} type="submit" value="Submit" />
              {imageAddedToSightingId && <a href={`/view-sighting/${imageAddedToSightingId}`}>View Sighting</a>}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

AddImage.defaultProps = {
  engines: null,
  roads: null,
};

AddImage.propTypes = {
  engines: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  roads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  recentlySeenEngines: PropTypes.array.isRequired,
};

const mapStateToProps = (state, props) => {
  const { engines } = state;
  return {
    recentlySeenEngines: engines.recentlySeenEngines,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { getRecentlySeenEngines }
  )(AddImage)
);
