import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import Sighting from './Sighting';
import Utils from './Utils';
import Locations from './Locations';
import { getSightings, getLocations } from '../actions';

class ViewSightings extends Component {
  constructor(props) {
    super(props);
    this.prefetchNextPage = this.prefetchNextPage.bind(this);
    this.goToPrevPage = this.goToPrevPage.bind(this);
    this.goToNextPage = this.goToNextPage.bind(this);
  }

  componentDidMount() {
    this.doINeedToGetStuff();
  }

  componentDidUpdate() {
    this.doINeedToGetStuff();
  }

  getSightingsForPageAndLocation(page, locationPass) {
    const location = locationPass;
    let locationId = location;
    if (!Utils.isNumeric(locationId)) {
      locationId = this.getLocationIdFromSlug(location);
    }
    const { sightings } = this.props;
    const sightingsForLocation = sightings[locationId];

    if (location !== undefined && location !== null && !/^\d+$/.test(locationPass)) {
      if (!sightingsForLocation) {
        return [];
      }
      return Object.keys(sightingsForLocation)
        .filter(sighting => sightingsForLocation[sighting].page.toString() === page.toString())
        .reduce((obj, key) => {
          obj[key] = sightingsForLocation[key];
          return obj;
        }, {});
    }

    const toUse = sightings.all;
    const first = Object.keys(toUse).filter(sighting => {
      return toUse[sighting].page === parseInt(page, 10);
    });
    return first.reduce((obj, key) => {
      obj[key] = toUse[key];
      return obj;
    }, {});
  }

  getLocationIdFromSlug() {
    return this.getCurrentLocationId();
  }

  getCurrentLocationId() {
    let id = 'all';
    const { locations, locationSlug } = this.props;
    if (locations.length === 0 || Object.keys(locations).length === 0) {
      return NaN;
    }
    Object.keys(locations).forEach(key => {
      let locationPass = locations[key];
      if (locationPass && locationPass.name && Utils.slugify(locationPass.name) === locationSlug) {
        id = locationPass.id;
      }
    });
    return id;
  }

  // getSightingsForPage(page) {
  //   const { currentLocation } = this.state;
  //   const { locationSlug, sightings } = this.props;
  //   const locationId = this.getCurrentLocationId();
  //   if (
  //     ((currentLocation !== null && !Number.isNaN(currentLocation)) ||
  //       (locationSlug !== undefined && locationSlug !== null)) &&
  //     !/^\d+$/.test(currentLocation)
  //   ) {
  //     return Object.keys(sightings)
  //       .filter(sighting => {
  //         const s2 = sightings[sighting];
  //         // if(s2) {
  //         //   console.log(s2.pageByLocation[location]);
  //         // }
  //         return s2.location === locationId && s2.pageByLocation && s2.pageByLocation[locationId] === page;
  //       })
  //       .reduce((obj, key) => {
  //         obj[key] = sightings[key];
  //         return obj;
  //       }, {});
  //   }
  //   return Object.keys(sightings)
  //     .filter(sighting => {
  //       return sightings[sighting].page === page;
  //     })
  //     .reduce((obj, key) => {
  //       obj[key] = sightings[key];
  //       return obj;
  //     }, {});
  // }

  getNextPageUrl() {
    const { page } = this.props;
    const nextPage = page + 1;
    const locid = this.getCurrentLocationId();
    const piece = `&location=${locid}`;
    return `/get-sightings?page=${nextPage}${!Number.isNaN(locid) ? piece : ''}`;
  }

  getLocationSlug() {
    const idval = this.getCurrentLocationId();
    const { locations } = this.props;
    let { locationSlug } = this.props;

    if (locationSlug === null || Utils.isNumeric(locationSlug)) {
      if (!locations) {
        locationSlug = null;
      } else if (locations.length) {
        locationSlug = locations[idval.toString()];
      }
    }
    return !Number.isNaN(idval) && idval !== null && locationSlug !== null ? `${locationSlug}/` : '';
  }

  getLocationForSighting(sightingObj) {
    const { locations } = this.props;
    return locations[sightingObj.location.toString()];
  }

  goToPrevPage(e) {
    const { page, locationSlug, history } = this.props;
    let v = parseInt(Utils.isNumeric(locationSlug) ? locationSlug : page, 10);
    v -= 1;

    const str = `/view-sightings/${this.getLocationSlug()}${v}`;
    history.push(str);
    e.preventDefault();
    return false;
  }

  goToView(event) {
    const { history } = this.props;
    history.push(event);
  }

  goToNextPage(e) {
    const { page, locationSlug, history } = this.props;
    let v = parseInt(Utils.isNumeric(locationSlug) ? locationSlug : page, 10);
    v += 1;
    const str = `/view-sightings/${this.getLocationSlug()}${v}`;
    history.push(str);
    e.preventDefault();
    return false;
  }

  prefetchNextPage() {
    console.warn(`prefetch next page ${this.getNextPageUrl()}`);
    const preloadLink = document.createElement('link');
    preloadLink.href = this.getNextPageUrl();
    preloadLink.rel = 'preload';
    preloadLink.as = 'script';
    document.head.appendChild(preloadLink);
  }

  doINeedToGetStuff() {
    const { locations, getSightings } = this.props;
    let { page, locationSlug } = this.props;
    if (Utils.isNumeric(locationSlug)) {
      page = locationSlug;
      locationSlug = null;
    }

    if (locations === null || Object.keys(locations).length === 0) {
      getLocations();
    }

    const p = Utils.isNumeric(locationSlug) ? locationSlug : page;
    const sightingsForSpecificPageAndLocation = this.getSightingsForPageAndLocation(p, locationSlug);
    if (!sightingsForSpecificPageAndLocation || Object.keys(sightingsForSpecificPageAndLocation).length === 0) {
      getSightings(p, locationSlug);
    } else {
      this.render();
    }
  }

  render() {
    const perPage = 10;
    const { locations, page, locationSlug, sightings, sightingCountByLocation } = this.props;
    const totalSightingsToUse = sightingCountByLocation[this.getCurrentLocationId()];

    const totalPages = Math.ceil(totalSightingsToUse / perPage);
    let prevButton;
    let nextButton;
    const pageToRender = parseInt(Utils.isNumeric(locationSlug) ? locationSlug : page, 10);
    const locstr = this.getLocationSlug();
    if (pageToRender <= 1) {
      prevButton = '';
    } else {
      const hrefPrev = `/view-sightings/${locstr}${pageToRender - 1}`;
      prevButton = (
        <a onClick={this.goToPrevPage} href={hrefPrev}>
          Prev
        </a>
      );
    }
    if (pageToRender === totalPages) {
      nextButton = '';
    } else {
      const hrefNext = `/view-sightings/${locstr}${pageToRender + 1}`;
      nextButton = (
        <a
          onFocus={this.prefetchNextPage}
          onMouseOver={this.prefetchNextPage}
          onClick={this.goToNextPage}
          href={hrefNext}
        >
          Next
        </a>
      );
    }
    let firstButton = null;
    let lastButton = null;

    if (pageToRender !== totalPages) {
      lastButton = <a href={`/view-sightings/${locstr}${totalPages}`}>Last Page</a>;
    }
    if (page > 1) {
      firstButton = <a href={`/view-sightings/${locstr}`}>First Page</a>;
    }

    // const currentPageOfSightings = this.getSightingsForPage(pageToRender);
    const currentPageOfSightings = this.getSightingsForPageAndLocation(
      parseInt(Utils.isNumeric(locationSlug) ? locationSlug : page, 10),
      locationSlug,
      sightings
    );

    let inVal = false;
    let exitVal = false;

    if (
      currentPageOfSightings !== undefined &&
      currentPageOfSightings !== null &&
      Object.keys(currentPageOfSightings).length > 0
    ) {
      exitVal = true;
    } else {
      inVal = true;
    }

    return (
      <div className="root-component">
        <h1>View Sightings</h1>
        <Locations locations={locations} />
        {firstButton}
        &nbsp;
        {prevButton}
        <span>
          {pageToRender} / {totalPages}
        </span>
        {nextButton}
        &nbsp;
        {lastButton}
        <div className="sightings-listing">
          <CSSTransition in={inVal} exit={exitVal} appear timeout={500} classNames="fade" unmountOnExit>
            <div className="loader">Loading...</div>
          </CSSTransition>
          {currentPageOfSightings &&
            Object.keys(currentPageOfSightings).map(key => {
              return (
                <Sighting
                  showLocation
                  location={this.getLocationForSighting(currentPageOfSightings[key])}
                  className="sightings-listing__listing"
                  key={key}
                  locationSlug={this.getLocationSlug()}
                  sightingId={currentPageOfSightings[key].id}
                />
              );
            })}
        </div>
        <style jsx global>{`
          .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transition: opacity 0.5s ease-out;
            z-index: 10;
          }

          .fade-exit {
            transition: opacity 0.5s ease-out;
            opacity: 1;
          }
          .fade-exit-active,
          .fade-exit-done {
            opacity: 0;
          }
        `}</style>
      </div>
    );
  }
}

ViewSightings.defaultProps = {
  locationSlug: null,
  page: 1,
  locations: null,
  sightings: null,
  sightingCountByLocation: null,
  // totalSightings: null,
  // totalSightingsByLocation: null,
};

ViewSightings.propTypes = {
  locations: PropTypes.object,
  getSightings: PropTypes.func.isRequired,
  locationSlug: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sightings: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  sightingCountByLocation: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    locations: state.locations,
    sightings: state.sightings.sightingsByLocation,
    sightingCountByLocation: state.sightings.totalSightingsByLocation,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { getSightings, getLocations }
  )(ViewSightings)
);
