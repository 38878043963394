import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ILocation from 'interfaces/ILocation';
import IAppState from 'interfaces/IAppState';
import Utils from './Utils';
import { getLocations } from '../actions';

type LocationsProps = {
  piece: string;
};

const Locations: React.FC<LocationsProps> = ({ piece }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const locations: Array<ILocation> = useSelector((state: IAppState) => state.locations);
  if (!locations || locations.length === 0 || Object.keys(locations).length === 0) {
    dispatch(getLocations());
  }

  let locs = Array.from(locations);
  if (locs.length === 0) {
    locs = Object.values(locations);
  }
  locs.sort((a, b) => (parseInt(a.sightings, 10) < parseInt(b.sightings, 10) ? -1 : 1));
  return (
    <nav className="locations-listing">
      {locations &&
        locs.reverse().map((location) => {
          const locObj = location;
          if (!locObj) return null;
          const link = `/view-${piece}/${locObj && locObj.name ? Utils.slugify(locObj.name) : ''}`;
          if(!locObj.name || !locObj.sightings) {
            return null;
          }
          return (
            <a
              key={locObj.id}
              onClick={(e) => {
                e.preventDefault();
                history.push(link);
                return false;
              }}
              className="locations-listing-link"
              href={link}
            >
              {locObj.name} ({locObj.sightings})
            </a>
          );
        })}
    </nav>
  );
};

Locations.defaultProps = {
  piece: 'sightings',
};

Locations.propTypes = {
  piece: PropTypes.string,
};

export default Locations;
