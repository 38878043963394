import _ from 'lodash';
import {
  GET_ENGINES,
  GET_PICTURES,
  GET_MOST_SEEN_ENGINES,
  GET_NEWEST_ENGINES,
  GET_RECENTLY_SEEN_ENGINES,
} from '../actions';

const defaultState = {
  totalEnginesByRoad: {},
  // totalPagesByRoad: {},
  mostSeenEngines: [],
  newestEngines: [],
  pictures: null,
  recentlySeenEngines: [],
  enginesByRoad: {
    all: {},
  },
};

const addPage = (object, page) => {
  const ret = {};
  Object.keys(object).forEach(key => {
    ret[object[key].id] = _.extend({ page }, object[key]); // do we add 1 to the page?
  });
  return ret;
};

export default (state = defaultState, action) => {
  const newTotalEnginesByRoad = { ...state.totalEnginesByRoad };

  let locationId = action && action.payload ? action.payload.road : 'all';
  if (locationId === null) {
    locationId = 'all';
  }
  const newEnginesByRoad = { ...state.enginesByRoad };
  const mapped = action.payload && action.payload.engines ? _.mapKeys(action.payload.engines, 'id') : null;
  switch (action.type) {
    case GET_NEWEST_ENGINES:
      return {
        mostSeenEngines: state.mostSeenEngines,
        totalEnginesByRoad: state.totalEnginesByRoad,
        pictures: state.pictures,
        enginesByRoad: state.enginesByRoad,
        newestEngines: action.payload,
        recentlySeenEngines: state.recentlySeenEngines,
      };
    case GET_RECENTLY_SEEN_ENGINES:
      return {
        mostSeenEngines: state.mostSeenEngines,
        totalEnginesByRoad: state.totalEnginesByRoad,
        pictures: state.pictures,
        enginesByRoad: state.enginesByRoad,
        newestEngines: state.newestEngines,
        recentlySeenEngines: action.payload,
      };

    case GET_MOST_SEEN_ENGINES:
      return {
        mostSeenEngines: action.payload,
        totalEnginesByRoad: state.totalEnginesByRoad,
        pictures: state.pictures,
        enginesByRoad: state.enginesByRoad,
        newestEngines: state.newestEngines,
        recentlySeenEngines: state.recentlySeenEngines,
      };

    case GET_PICTURES:
      return {
        totalEnginesByRoad: state.totalEnginesByRoad,
        enginesByRoad: state.enginesByRoad,
        pictures: action.payload,
        mostSeenEngines: state.mostSeenEngines,
        newestEngines: state.newestEngines,
        recentlySeenEngines: state.recentlySeenEngines,
      };
    case GET_ENGINES:
      newTotalEnginesByRoad[locationId] = action.payload.count;
      if (!newEnginesByRoad[locationId]) {
        newEnginesByRoad[locationId] = {};
      }

      newEnginesByRoad[locationId] = {
        ...newEnginesByRoad[locationId],
        ...addPage(mapped, action.payload.page),
      };
      console.log(newEnginesByRoad);

      if (action.payload.engines.length > 0) {
        return {
          totalEnginesByRoad: newTotalEnginesByRoad,
          // sightings: { ...state.sightings, ...addPage(action.payload.trains, action.payload.page) },
          enginesByRoad: newEnginesByRoad,
          mostSeenEngines: state.mostSeenEngines,
          newestEngines: state.newestEngines,
          recentlySeenEngines: state.recentlySeenEngines,
          engineCountsByRoad: action.payload.engineCountsByRoad,
        };
      }
      return state;
    // break;
    default:
      return state;
    // break;
  }
};
