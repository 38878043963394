import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { createStore, compose, applyMiddleware } from 'redux';
import './index.css';
import App from './App';
import register from './registerServiceWorker';
import reducers from './reducers';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;

const middleware = [reduxThunk];
if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { logger } = require('redux-logger');
  middleware.push(logger);
}
// Creates redux store with initial state and saga middleware

const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
console.log('attempt register');
register();
