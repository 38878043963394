import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RoadNameNumberComboBox from './RoadNameNumberComboBox';
import Utils from './Utils';

class SingleImageInput extends Component {
  constructor(props) {
    super(props);
    if (props.engineData) {
      console.log('SIP');
      console.dir(props.engineData);
    }
    this.state = {
      engineCount: 1,
      allValid: false,
      comboBoxes: [], // array of booleans
      image: '',
    };
    this.addOneEngine = this.addOneEngine.bind(this);
    this.isValid = this.isValid.bind(this);
    this.removeOne = this.removeOne.bind(this);
    this.setImage = this.setImage.bind(this);
    this.comboBoxValidity = this.comboBoxValidity.bind(this);
  }

  setValidity(nameval, val) {
    const { setValidity } = this.props;
    console.log(`setting here:${nameval} - ${val}`);
    setValidity(nameval, val);
  }

  setImage(inputPass) {
    const img = inputPass.value;
    const { name, setValidity } = this.props;
    if (img === '') {
      this.setState({
        image: img,
        allValid: false,
      });
      setValidity(name, false);
    } else {
      const t = this;
      const inputForFile = inputPass;
      const files = inputForFile.files ? inputForFile.files : [];
      if (!files.length || !window.FileReader) return; // no file selected, or no FileReader           support
      if (files.length && window.FileReader && /^image/.test(files[0].type)) {
        // only image file
        const reader = new FileReader(); // instance of the FileReader
        reader.readAsDataURL(files[0]); // read the local file
        reader.onloadend = function() {
          // set image data as background of div
          t.props.passUpImage(t.props.name, reader.result);
          if (t.props.doIHaveAnImage) {
            t.props.doIHaveAnImage();
          }
        };
      }
      this.setState({
        image: img,
      });
      this.isValid(img);
    }
  }

  removeOne() {
    const { removeEngine, name } = this.props;
    const { engineCount } = this.state;
    this.setState({
      engineCount: engineCount - 1,
    });
    if (removeEngine) {
      removeEngine(name);
    }
  }

  addOneEngine() {
    const { setValidity, name } = this.props;
    const { engineCount } = this.state;
    this.setState({
      engineCount: engineCount + 1,
      allValid: false,
    });
    setValidity(name, false);
  }

  isValid(cbs) {
    console.log('single inpmt isValid');
    const { comboBoxes } = this.state;
    let looper = comboBoxes;
    if (cbs && typeof cbs !== 'string') {
      console.log('oops');
      looper = cbs;
    } else {
      console.log('no oops');
    }

    let valid = true;
    let validCount = 0;
    Object.keys(looper).forEach(key => {
      if (looper[key] === true) {
        validCount += 1;
      }
    });
    const { engineCount, image } = this.state;
    if (validCount < engineCount) {
      valid = false;
    }
    if (typeof cbs !== 'string' && image === '') {
      valid = false;
    }

    this.setState({ allValid: valid });
    const { name, setValidity } = this.props;
    setValidity(name, valid);
    return valid;
  }

  comboBoxValidity(nameval, val) {
    console.log('set cb1');
    const { comboBoxes } = this.state;
    const cbs = { ...comboBoxes };
    cbs[nameval] = val;
    this.setState({
      comboBoxes: cbs,
    });
    this.isValid(cbs);
  }

  render() {
    const rows = [];
    const { name, engines, roads, imageData, engineData } = this.props;
    let { engineCount } = this.state;
    let passed = true;
    if (Object.keys(engineData).length > engineCount) {
      engineCount = Object.keys(engineData).length;
    }

    for (let i = 0; i < engineCount; i += 1) {
      if (!engineData || !engineData[i] || !Utils.validEngine(engineData[i].road, engineData[i].num)) {
        passed = false;
      }
      if (i > 0) {
        rows.push(
          <div key={`rncb-wrap${i}`}>
            <RoadNameNumberComboBox
              name={`${name}-${i}`}
              engines={engines}
              key={`rncb${i}`}
              roads={roads}
              additionalOnChange={this.props.dataChange}
              setRoadNameNumberComboBoxValidity={this.comboBoxValidity}
              engineData={engineData[i]}
            />
            <button type="button" onClick={this.removeOne}>
              Remove
            </button>
          </div>
        );
      } else {
        const nv = `${name}-${i}`;
        rows.push(
          <div key={`rncb-wrap${i}`}>
            <RoadNameNumberComboBox
              name={nv}
              key={`rncb${i}`}
              engines={engines}
              roads={roads}
              additionalOnChange={this.props.dataChange}
              setRoadNameNumberComboBoxValidity={this.comboBoxValidity}
              engineData={engineData[i]}
            />
          </div>
        );
      }
    }
    const divStyle = imageData
      ? {
          backgroundImage: `url(${imageData})`,
        }
      : {};

    const { allValid } = this.state;

    if (!imageData || !imageData.length) {
      passed = false;
    }
    return (
      <fieldset className={`single-image-input single-image-input--${allValid || passed ? 'valid' : 'invalid'}`}>
        <h2>Add Image</h2>
        <div className="single-image-input__image-preview" style={divStyle} />
        <input
          onChange={e => {
            this.setImage(e.currentTarget);
          }}
          required
          type="file"
          name={`image-${name}`}
          id={`image-${name}`}
        />
        {rows}
        <button type="button" onClick={this.addOneEngine}>
          Add Engine
        </button>
      </fieldset>
    );
  }
}

SingleImageInput.propTypes = {
  engines: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  roads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setValidity: PropTypes.func,
  dataChange: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageData: PropTypes.string,
  engineData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

SingleImageInput.defaultProps = {
  name: null,
  roads: null,
  engines: null,
  setValidity: null,
  imageData: null,
  dataChange: null,
};

export default SingleImageInput;
