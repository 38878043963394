import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import AJAXCall from './ajax';
import Utils from './Utils';
import EngineTag from './EngineTag';
import MostViewedEngines from './MostViewedEngines';
import NewestEngines from './NewestEngines';
import { getEngines } from '../actions';
import IEngine from 'interfaces/IEngine';
import IRoad from 'interfaces/IRoad';
import IAppState from 'interfaces/IAppState';

type EngineListingProps = {
  road: string;
  page: string;
};

const EngineListing: React.FC<EngineListingProps> = ({ road, page, addEngines }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  let roadAssignment = '';

  const r = road === undefined || Utils.isNumeric(road) ? 'all' : road;
  const roads: Array<IRoad> = useSelector((appState: IAppState) => appState.roads);
  const engines: Array<IEngine> = useSelector((appState: IAppState) => appState.engines);

  const { totalEnginesByRoad, engineCountsByRoad, enginesByRoad } = engines;
  let totalEngines = totalEnginesByRoad[r] || 0;
  if (!totalEnginesByRoad['all'] && (road === undefined || Utils.isNumeric(road))) {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    totalEngines = totalEnginesByRoad.reduce(reducer);
  } else {
    totalEngines = totalEnginesByRoad['all'];
  }
  let initialPage = 0;
  if (road && !/[0-9]/.test(road)) {
    // ns/1
    roadAssignment = road;
    if (page) {
      initialPage = page - 1;
    }
  } else if (road && road !== '' && /[0-9]/.test(road)) {
    initialPage = parseInt(road, 10) - 1;
  }

  const [state, setState] = useState({
    road: roadAssignment,
    currentPage: initialPage,
    pagesRetrieved: [],
  });

  const mode = () => {
    const { road } = state;
    return road && road !== '' ? 'road' : 'all';
  };

  const getEnginesForPage = (page) => {
    if (mode() === 'all') {
      const otherRet = Object.keys(enginesByRoad.all)
        .filter((engineKey) => {
          return parseInt(enginesByRoad.all[engineKey].page, 10) === parseInt(page, 10);
        })
        .reduce((obj, key) => {
          obj[key] = enginesByRoad.all[key];
          return obj;
        }, {});
      return otherRet;
    }

    if (!engines.enginesByRoad[road]) {
      return [];
    }

    const ret = Object.keys(enginesByRoad[road])
      .filter((engine) => enginesByRoad[road][engine].page.toString() === page.toString())
      .reduce((obj, key) => {
        obj[key] = enginesByRoad[road][key];
        return obj;
      }, {});
    return ret;
  };

  const { currentPage } = state;

  document.title = 'TrainTracker: View Engines';
  const fp = getEnginesForPage(currentPage);
  const firstPage = Object.keys(fp).length === 20;
  if (!firstPage) {
    dispatch(getEngines(Utils.pageOrRoad(page, road, 'page'), Utils.pageOrRoad(road, page, 'road')));
  }

  const enginesNeeded = getEnginesForPage(currentPage);
  if (Object.keys(enginesNeeded).length === 0) {
    // !== 20) {
    dispatch(getEngines(Utils.pageOrRoad(page, road, 'page'), Utils.pageOrRoad(road, page, 'road')));
  }

  const goToPrevPage = (e) => {
    const { currentPage } = state;

    let v = currentPage;
    v = currentPage - 1;

    setState({
      ...state,
      currentPage: v,
    });

    history.push(`/view-engines/${v}`);
    e.preventDefault();
    return false;
  };

  const goToNextPage = (e) => {
    const { currentPage } = state;
    const nextPage = currentPage + 1;
    const { pagesRetrieved } = state;

    const pr = { ...pagesRetrieved };
    pr[nextPage] = true;

    setState({
      ...state,
      currentPage: nextPage,
      pageInProgress: nextPage,
      pagesRetrieved: pr,
    });

    if (Number.isNaN(page)) {
      history.push(`/view-engines/${nextPage + 1}`);
      dispatch(getEngines(nextPage));
    } else {
      history.push(`/view-engines/${road && road.length ? `${road}/` : ''}${nextPage + 1}`);
      new AJAXCall(
        `${Utils.Host}/api/get-engines?page=${nextPage}${road && road.length ? `&road=${road}` : ''}`,
        (data) => {
          setState({ pageInProgress: null });
          addEngines(data, nextPage, state.road);
        }
      );
    }
    e.preventDefault();
    return false;
  };

  const goToPage = (pageParam, roadParam = null) => {
    history.push(`/view-engines/${roadParam !== null && roadParam !== '' ? `${roadParam}/` : ''}${pageParam}`);
  };

  const perPage = 20;
  if (!roads) {
    return null;
  }
  const totalPages = Math.ceil(totalEngines / perPage);

  let prevButton;
  let nextButton;

  const pageToRender = currentPage;
  if (pageToRender === 0) {
    prevButton = '';
  } else {
    const hrefPrev = `/view-engines/${currentPage}`;
    prevButton = (
      <a onClick={goToPrevPage} href={hrefPrev}>
        Prev
      </a>
    );
  }
  if (pageToRender === totalPages - 1) {
    nextButton = '';
  } else {
    const hrefNext = `/view-engines/${road && road.length ? `${road}/` : ''}${currentPage + 2}`;
    nextButton = (
      <a onClick={goToNextPage} href={hrefNext}>
        Next
      </a>
    );
  }

  const currentPageOfSightings = getEnginesForPage(pageToRender);

  let lastButton = null;
  let firstButton = null;

  if (pageToRender !== 0) {
    firstButton = (
      <a
        onClick={(e) => {
          goToPage(0);
          e.preventDefault();
          return false;
        }}
        href="/view-engines/1"
      >
        First
      </a>
    );
  }
  if (pageToRender !== totalPages - 1) {
    lastButton = (
      <a
        onClick={(e) => {
          goToPage(totalPages, road);
          e.preventDefault();
          return false;
        }}
        href={`/view-engines/${road !== null && road !== '' ? `${road}/` : ''}${totalPages}`}
      >
        Last
      </a>
    );
  }
  return (
    <div className="root-component">
      <h1>
        View Engines&nbsp;
        <span>({totalEngines})</span>
      </h1>
      <nav className="locations-listing">
        <ul className="subnav-list">
          {roads &&
            Object.keys(roads).map((roadToUse) => {
              const s = Utils.slugify(roads[roadToUse]['short_name']);
              const link = `/view-engines/${s}`;
              return (
                <li key={s} className="subnav-list__item">
                  <a
                    onClick={(event) => {
                      event.preventDefault();
                      const toEdit = { ...state };
                      toEdit.currentPage = 0;
                      toEdit.road = s;
                      setState({
                        ...state,
                        toEdit,
                      });
                      // getEnginesFromDB(0, s);
                      history.push(link);
                      return false;
                    }}
                    className="locations-listing-link"
                    href={link}
                  >
                    {roads[roadToUse].name}
                    {totalEnginesByRoad[roadToUse] ? `(${totalEnginesByRoad[roadToUse]})` : ''}
                    {Object.keys(totalEnginesByRoad).map((item) =>
                      item === roads[roadToUse]['short_name'].toLowerCase() ? `(${totalEnginesByRoad[item]})` : null
                    )}
                    {roads[roadToUse].id && engineCountsByRoad && (` (${engineCountsByRoad[roads[roadToUse].id]})`)}
                  </a>
                </li>
              );
            })}
        </ul>
      </nav>
      <div className="engine-listing__column">
        <div className="engine-listing__list">
          <h2 className="full">All Engines:</h2>
          <nav>
            {firstButton}
            &nbsp;
            {prevButton}
            <span>
              {pageToRender + 1}
              {' / '}
              {totalPages}
            </span>
            {nextButton}
            &nbsp;
            {lastButton}
          </nav>
          <ul className="plain-list plain-list--stack">
            {Object.keys(currentPageOfSightings).map((key) => {
              if (!roads) {
                return;
              }
              return (
                <li key={key} className="single-engine single-engine--in-list">
                  <EngineTag
                    road={roads.filter((roadParam) => currentPageOfSightings[key].road === roadParam.id)[0].short_name}
                    num={currentPageOfSightings[key].num}
                    short
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <NewestEngines roads={roads} />
        <MostViewedEngines />
      </div>
    </div>
  );
};

EngineListing.defaultProps = {
  page: NaN,
  road: null,
};

EngineListing.propTypes = {
  road: PropTypes.string,
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EngineListing;
