/* eslint no-undef: "error" */
const slugify = require('slugify');
//const env = require('dotenv').config();
let hostval = '';//window.location.origin.indexOf('localhost' >= 0) ? 'https://localdev.traintrackerphp.com' : '';
// if (window.location.origin.indexOf('johnstrawserjr.com') >= 0) {
//   hostval = 'https://traintracker.johnstrawserjr.com';
// }

class Utils {
  static Host = hostval;
  static dayOfWeek(num) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[num];
  }

  static validEngine(road, num) {
    return road && num && road.length && num.length;
  }

  static getRoadId(roadName, roads) {
    let ret = null;
    Object.keys(roads).forEach(roadId => {
      if (roads[roadId] === roadName) {
        ret = roadId;
      }
    });
    return ret;
  }

  static getMonthName(month) {
    const months = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December',
    };
    return months[month];
  }

  static pageOrRoad(a, b, whatIwant) {
    switch (whatIwant) {
      case 'road':
        if (Utils.isNumeric(a)) {
          return null;
        }
        return a;
      case 'page':
        if (!Utils.isNumeric(a) && !Utils.isNumeric(b)) {
          return 0;
        }
        if (Utils.isNumeric(a) && !Utils.isNumeric(b)) {
          return parseInt(a, 10) - 1;
        }
        if (Utils.isNumeric(b)) {
          return parseInt(b, 10) - 1;
        }
        break;
      default:
        return '';
      // break;
    }
  }

  static getLocationFromSlug(locationSlug, locations) {
    try {
      return Object.entries(locations).filter(item => {
        return Utils.slugify(item[1].name) === locationSlug;
      })[0][1];
    } catch (e) {
      return null;
    }
  }

  static slugify(strPass) {
    const str = strPass
      .replace('/', '-')
      .replace('(', '')
      .replace(')', '');

    return slugify(str, { lower: true });
  }

  static isNumeric(val) {
    return /^\d+$/.test(val);
  }

  static getOppositeColorMode(mode) {
    if (mode === 'light') {
      return 'dark';
    }
    return 'light';
  }

  static getSrcSet({ filename, checkSized = false, errored = false, sized = false, breakCache = false } = {}) {
    let srcSet = '';
    const f = filename.replace('uploads/', '');

    const bucket = process.env.REACT_APP_BUCKET_NAME;

    if ((checkSized || errored) && sized === false) {
      srcSet = `//storage.googleapis.com/${bucket}/${f} 3000w`;
    } else {
      const sizes = [0, 1, 2, 3, 4];
      const widths = [2000, 1500, 1000, 500, 250];
      sizes.forEach((object, index) => {
        const file = f.replace('.', `_${index + 1}x.`);
        srcSet += `//storage.googleapis.com/${bucket}/${file}${
          breakCache || window.location.href.indexOf('?cacheBust=true') >= 0 ? `?ignoreCache=${Math.random()}` : ''
        }`;
        srcSet += ' ';
        srcSet += `${widths[index]}w`;
        if (index !== sizes.length - 1) {
          srcSet += ',';
        }
      });
    }

    return srcSet;
  }
}

export default Utils;
