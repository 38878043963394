import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Utils from './Utils';

type DateFromTSProps = {
  ts: string;
  id: number;
};

const DateFromTS: React.FC<DateFromTSProps> = ({ ts, id }) => {
  const date = new Date(ts);
  const history = useHistory();
  return (
    <span>
      {Utils.getMonthName(date.getMonth())}&nbsp;
      {date.getDate()},&nbsp;
      {date.getFullYear()}
      <a
        onClick={e => {
          e.preventDefault();
          history.push(`/view-sighting/${id}`);
          return false;
        }}
        href={`/view-sighting/${id}`}
        className="goto"
      >
        View Sightings
      </a>
      <style jsx>{`
        .goto {
          width: 45px;
          height: 40px;
          border-radius: 3px;
          transform: translateY(4px);
          margin-left: 6px;
          text-indent: -999em;
          display: inline-block;
          background: black;
          transition: opacity 0.2s ease-out;
          position: relative;
        }

        .goto:after {
          border-radius: 3px;
          left: 0%;
          top: 50%;
          border: solid transparent;
          content: ' ';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(255, 255, 255, 0);
          border-left-color: #fff;
          border-width: 30px;
          margin-top: -30px;
          transition: opacity 0.2s ease-out;
          transform: scale(0.5, 0.4) translate(-0%, -0%);
        }
        .goto:hover:after {
          opacity: 0.8;
        }
      `}</style>
    </span>
  );
};

DateFromTS.propTypes = {
  ts: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default DateFromTS;
