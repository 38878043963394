import { GET_ROADS } from '../actions';

export default (state = [], action) => {
  switch (action.type) {
    case GET_ROADS:
      return action.payload;
    // break;
    default:
      return state;
    // break;
  }
};
