import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

class NumberAnimationAlt extends React.Component {
  // todo - add zero spacers
  static AnimationTime = 80;

  constructor(props) {
    super(props);
    const { end, start } = this.props;
    this.state = {
      end,
      currentNumber: start,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      end: props.end,
      start: props.start,
      currentNumber: state.currentNumber,
      animate: !Number.isNaN(state.end) && !Number.isNaN(props.end),
    };
  }

  render() {
    const { end } = this.props;
    const { currentNumber, animate } = this.state;
    const placeholder =
      end
        .toString()
        .split('')
        .map(() => '0') || '0';

    if (animate) {
      const newCurrentNumber = Math.min(end, currentNumber + 1);
      setTimeout(() => {
        if (currentNumber < end) {
          this.setState({
            currentNumber: newCurrentNumber,
          });
        }
      }, NumberAnimationAlt.AnimationTime);
    }

    let start = 0;
    const transitions = [];
    let index = 0;
    while (start <= end) {
      transitions.push(
        <CSSTransition
          key={index}
          appear
          exit={currentNumber > index}
          // unmountOnExit
          in={currentNumber === index}
          timeout={index * NumberAnimationAlt.AnimationTime}
          classNames="move-in-out"
        >
          <div className="number1">{start}</div>
        </CSSTransition>
      );
      start += 1;
      index += 1;
    }

    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <div>
          {animate ? (
            <>
              <div className="number1" style={{ position: 'relative' }}>
                {placeholder}
              </div>
              {transitions}
            </>
          ) : (
            <div className="number1" style={{ position: 'relative' }}>
              {placeholder}
            </div>
          )}
        </div>
        <style jsx global>{`
          .number1 {
            letter-spacing: -4px;
            font-size: 6.75rem;
            transition: transform ${NumberAnimationAlt.AnimationTime}ms ease-out,
              opacity ${NumberAnimationAlt.AnimationTime}ms ease-out;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: right;
            opacity: 0;
            transform: translateY(100%);
            will-change: transform;
          }
          .number1:nth-child(2) {
            transform: none;
            opacity: 1;
          }

          .move-in-out-exit-done:nth-child(2) {
            transform: translateY(-100%);
            opacity: 0;
          }
          .move-in-out-appear {
            opacity: 0;
            transform: translateY(100%);
          }
          .move-in-out-appear-done {
            opacity: 1;
            transform: none;
          }
          .move-in-out-exit-active {
            transform: translateY(-100%);
            opacity: 0;
          }
          .move-in-out-enter.move-in-out-enter-active,
          .move-in-out-enter-active {
            opacity: 1;
            transform: none;
          }
          .move-in-out-exit-done,
          .move-in-out-enter-done {
            transform: translateY(-100%);
            opacity: 0;
          }
          .move-in-out-enter-done:last-child {
            opacity: 1;
            transform: none;
          }
        `}</style>
      </div>
    );
  }
}

NumberAnimationAlt.defaultProps = {
  start: 0,
  end: NaN,
};

NumberAnimationAlt.propTypes = {
  end: PropTypes.number,
  start: PropTypes.number,
};

export default NumberAnimationAlt;
