import { ActionTypes } from '../actions';
const defaultState = null;

export default (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GetStats:
      return action.payload;
    default:
      return state;
    // break;
  }
};
