import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types'; // ES6
import Root from './components/Root';
import { getRoads } from './actions';
import IAppState from './interfaces/IAppState';

const App: React.FC = () => {
  const settings: any = useSelector((appState: IAppState) => appState.settings);
  const roads: any = useSelector((appState: IAppState) => appState.roads);
  const { colorMode } = settings;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    sightings: [],
    totalSightings: 0,
    engines: [],
    totalSightingsByLocation: [],
    totalEngines: 0,
  });

  const sightingsUrl = '/get-sightings';
  if (!roads || roads.length === 0) {
    dispatch(getRoads());
  }

  if (window.caches) {
    caches
      .match(sightingsUrl)
      .then((response) => {
        response.json().then((data) => {
          addSightings(data);
        });
        return response;
      })
      .catch(() => {
        // new AJAXCall(sightingsUrl, data => {
        //   t.addSightings(data);
        // });
      });
  }

  const addEngines = (enginesPass, page, optionalRoad) => {
    const { engines } = state;
    let { totalEngines } = state;

    enginesPass.engines.forEach(eng => {
      if (!optionalRoad || optionalRoad === '') {
        eng.page = page;
        if (enginesPass.count) {
          totalEngines = enginesPass.count;
        }
      } else {
        const v = `page-${optionalRoad}`;
        eng[v] = page;
      }
      engines[eng.id] = eng;
    });
    let obj;
    if (optionalRoad || optionalRoad !== '') {
      obj = {
        engines,
      };
    } else if (enginesPass && enginesPass.count_per_road) {
      obj = {
        engines,
        totalEngines: enginesPass.count,
      };
    } else {
      obj = {
        engines,
        totalEngines,
      };
    }
    setState(obj);
  }

  const addSighting = (sightingPass) => {
    if (sightingPass === null) {
      return;
    }
    const { sightings, engines } = state;
    const sid = parseInt(sightingPass.id, 10);
    if (sightings[sid]) {
      sightings[sid] = Object.assign(sightings[sid], sightingPass);
    } else {
      sightings[sid] = sightingPass;
    }
    // this.addEngineFromSighting(sightingPass);

    if (sightingPass.engines) {
      sightingPass.engines.forEach(eng => {
        engines[eng.id] = eng;
      });
    }

    setState({
      ...state,
      sightings,
      engines,
    });
  }

  const removeImageFromSighting = (imageId, sightingId) => {
    const { sightings } = state;
    sightings[sightingId].pictures.filter(obj => {
      return obj.id !== imageId;
    });
    setState({
      ...state,
      sightings,
    });
  }

  const addSightings = (sightingsPass, location = null, params: any = {}) => {
    const { totalSightingsByLocation, sightings, engines, totalSightings } = state;

    sightingsPass.trains.forEach(singleSighting => {
      let oldEngines = null;
      // let replaceEngines = false;
      if (
        sightings[singleSighting.id] &&
        sightings[singleSighting.id].engines &&
        singleSighting.engines &&
        sightings[singleSighting.id].engines.length > singleSighting.engines.length
      ) {
        oldEngines = sightings[singleSighting.id].engines;
        // replaceEngines = true;
        singleSighting.engines = oldEngines;
      }
      if (sightings[singleSighting.id]) {
        sightings[singleSighting.id] = Object.assign(sightings[singleSighting.id], singleSighting);
      } else if (!sightings[singleSighting.id] || !sightings[singleSighting.id].pictures) {
        sightings[singleSighting.id] = singleSighting;
      }
      sightings[singleSighting.id].page = sightingsPass.page;

      if (params.location && !Number.isNaN(params.page)) {
        if (!sightings[singleSighting.id].pageByLocation) {
          sightings[singleSighting.id].pageByLocation = {};
        }
        sightings[singleSighting.id].pageByLocation[params.location] = params.page;
      }
    });
    let ts = totalSightings;

    if (location !== null && !Number.isNaN(location)) {
      totalSightingsByLocation[location] = parseInt(sightingsPass.count, 10);
    } else if (sightingsPass.page === 0) {
      const newSightingCount = parseInt(sightingsPass.count, 10);
      if (newSightingCount > ts) {
        ts = newSightingCount;
      }
    }

    setState({
      ...state,
      sightings,
      totalSightings: ts,
      engines,
      totalSightingsByLocation,
    });
  };

  const { sightings, engines, totalSightings, totalSightingsByLocation } = state;

  return (
    <Root
      className={`color-mode--${colorMode}`}
      roads={roads}
      sightings={sightings}
      addSighting={addSighting}
      engines={engines}
      addSightings={addSightings}
      totalSightings={totalSightings}
      removeImageFromSighting={removeImageFromSighting}
      addEngines={addEngines}
      totalSightingsByLocation={totalSightingsByLocation}
    />
  );
}

App.propTypes = {
  // colorMode: PropTypes.string.isRequired,
};

// get roads?
export default App;
