import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocations } from '../actions';
import Locations from './Locations';
import ILocation from '../interfaces/ILocation';
import IAppState from '../interfaces/IAppState';

/* globals google */

const ViewLocations: React.FC<any> = props => {
  const dispatch = useDispatch();
  const locations: Array<ILocation> = useSelector((appState: IAppState) => appState.locations);
  const mapRef = useRef();
  const [map, setMap] = useState();
  const { options } = props;

  useEffect(() => {
    const onLoad = () => setMap(new google.maps.Map(mapRef.current, options));
    if (window.google) onLoad();
  }, [options]);

  const addEm = () => {
    const bounds = new google.maps.LatLngBounds();
    Object.keys(locations).forEach(locId => {
      const loc = locations[locId];
      const position = { lat: parseFloat(loc.lat), lng: parseFloat(loc.long) };
      const marker = new google.maps.Marker({ position, map });
      bounds.extend(marker.getPosition());
    });
    if (map && map !== undefined) {
      map.fitBounds(bounds);
    }
  };

  if (!locations || Object.keys(locations) === 0) {
    dispatch(getLocations());
  }
  // this.addEm = this.addEm.bind(this);

  if (Object.keys(locations).length > 0) {
    addEm();
  }

  return (
    <div>
      <div className="root-component">
        <h1>Map</h1>
        <Locations locations={locations} piece="location" />
      </div>
      <div ref={mapRef} style={{ width: '100%', minHeight: '500px' }} />
    </div>
  );
};

export default ViewLocations;

ViewLocations.defaultProps = {
  options: {
    center: { lat: 39.9612, lng: -82.9988 },
    zoom: 8,
  },
};

ViewLocations.propTypes = {
  options: Object,
};
