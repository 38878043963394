import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import IPicture from 'interfaces/IPicture';
import { getLocations, getAllEnginePhotos, getPictures } from '../actions';
import Sighting from './Sighting';
import TrainPicture from './TrainPicture';
// import ILocation from '../interfaces/ILocation';
// import ISighting from '../interfaces/ISighting';
import IAppState from '../interfaces/IAppState';

type ViewEngineProps = {
  engine: string;
  road: string;
};

const ViewEnginePhotos: React.FC<ViewEngineProps> = ({ engine, road }) => {
  const dispatch = useDispatch();
  const sightings = useSelector((appState: IAppState) => appState.sightings);
  const locations = useSelector((appState: IAppState) => appState.locations);
  const picturesToShow = sightings.picturesForEngine;

  if (Object.keys(locations).length === 0) {
    dispatch(getLocations());
  }
  try {
    if (picturesToShow === null || picturesToShow === undefined || picturesToShow.length === 0) {
      console.log('lets get em')
      dispatch(getAllEnginePhotos(road, engine));
    }
  } catch (e) {
    console.log('what happened');
    console.dir(e);
  }

  document.title = `TrainTracker: View Engine Photos ${road.toUpperCase()}${engine}`;

  return (
    <div className="root-component">
      <h1>
        View All Engine Photos:
        <span className={`roadname roadname--${road.toLowerCase()}`} style={{ textTransform: 'uppercase' }}>
          {road}
          {engine}
        </span>
      </h1>

      <div className="engine-sightings">
        {picturesToShow !== null &&
        picturesToShow !== undefined &&
        picturesToShow.map(({ sized, filename, id, engines, ts, blurhash }, index) => (
          <TrainPicture
                  sized={sized === 1}
                  enginesInPic={[]}
                  rotated={true}
                  timestamp={ts}
                  blurhash={blurhash}
                  key={id}
                  filename={filename}
                  imageId={id.toString()}
                  id={id}
                  index={index}
                />
            ))}
      </div>
    </div>
  );
};

// ViewEngine.defaultProps = {
//   engine: null,
//   road: null,
// };

ViewEnginePhotos.propTypes = {
  engine: PropTypes.string.isRequired,
  road: PropTypes.string.isRequired,
};

export default ViewEnginePhotos;
