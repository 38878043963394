import React from 'react';
import OnThisDay from './OnThisDay';

const HomePage = () => (
  <div className="root-component">
    <h1>HomePage</h1>
    <OnThisDay
     />
    <div>show a random one</div>
  </div>
);

export default HomePage;
