import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { createGetStatsAction } from '../actions';

const Stats = ({}) => {
  const stats = useSelector(state => state.stats);
  const dispatch = useDispatch();
  if (!stats) {
    dispatch(createGetStatsAction());
  }

  const s = {
    display: 'flex',
    flexWrap: 'wrap',
    listStylePosition: 'inside',
  };

  return (
    <main>
      <div className="root-component">
        <ul>
          <li>Pictures that haven't been rotated: {stats && stats.pictures}</li>
          <li>Sightings that haven't been finished: {stats && stats.sightingsCount}</li>
          <li>Sightings with pictures that haven't been rotated: {stats && stats.count}</li>
          <li>Sightings: <ol style={s}>{stats && stats.ids.map((id) => {
              return (<li key={`badimages-${id}`}><Link to={`/view-sighting/${id}`}>sighting {id}</Link>&nbsp;</li>);
          })}</ol></li>
          <li>Unfinished Sightings: <ol style={s}>{stats && stats.sightings.map((id) => {
              return (<li key={`unfinished-${id}`}><Link to={`/view-sighting/${id}`}>sighting {id}</Link>&nbsp;</li>);
          })}</ol></li>
        </ul>
      </div>
    </main>
  );
};

export default Stats;
