import _ from 'lodash';
import { GET_LOCATIONS, GET_SIGHTINGS } from '../actions';

export default (state = {}, action) => {
  switch (action.type) {
    case GET_SIGHTINGS:
    //   if (action.payload.location !== '') {
    //     const newState = { ...state };
    //     newState[action.payload.location] = {
    //       id: action.payload.location,
    //       name: action.payload.locationName,
    //     };
    //     return newState;
    //   }
      const existing = state[action.payload.location];//.filter(loc => loc.id === action.payload.location);
      if(!existing) {
        state[action.payload.location] = {
          id: action.payload.location,
          name: action.payload.locationName,
        }
      }
         
      return state;
    case GET_LOCATIONS:
      return { ...state, ..._.mapKeys(action.payload, 'id') };
    // break;
    default:
      return state;
    // break;
  }
};
