import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import IPicture from 'interfaces/IPicture';
import { useHistory } from 'react-router-dom';
import { getLocations, getSightings, getPictures, setAltId } from '../actions';
import Sighting from './Sighting';
// import ILocation from '../interfaces/ILocation';
// import ISighting from '../interfaces/ISighting';
import IAppState from '../interfaces/IAppState';

type ViewEngineProps = {
  engine: string;
  road: string;
};

type ViewEngineState = {
  indexToLoad: number;
};

const ViewEngine: React.FC<ViewEngineProps> = ({ engine, road }) => {
  const dispatch = useDispatch();
  const sightings = useSelector((appState: IAppState) => appState.sightings);
  const locations = useSelector((appState: IAppState) => appState.locations);
  const engines = useSelector((appState: IAppState) => appState.engines);
  const { pictures } = engines;

  const sightingsToShow = sightings.sightingsForEngine;
  const hasAltId = sightings.currentEngineHasAltId;

  const [state, setState] = useState({
    indexToLoad: 0,
  });
  if (Object.keys(locations).length === 0) {
    dispatch(getLocations());
  }
  try {
    if (sightingsToShow.length === 0) {
      dispatch(getSightings(0, null, road, engine));
    }
  } catch (e) {}

  document.title = `TrainTracker: View Engine ${road.toUpperCase()}${engine}`;
  if (!pictures || !pictures.length) {
    dispatch(getPictures(road, engine));
  }

  const onSightingLoad = () => {
    setState((oldState) => {
      return {
        ...oldState,
        indexToLoad: oldState.indexToLoad + 1,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData(document.getElementById('alt_id_form') as HTMLFormElement); // e.currentTarget);
    const oReq = new XMLHttpRequest();
    dispatch(setAltId(road, engine, fd.get('alt_id')));
    oReq.open('POST', `/api/save-alt-id?alt_id=${fd.get('alt_id')}&engine=${engine}&road=${road}`, true);
    oReq.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    // oReq.onload = function() {
    //   if (oReq.status === 200) {}
    // };
    oReq.send(fd);
    return false;
  };
  const history = useHistory();
  const { indexToLoad } = state;
  let oneToLoad = false;
  return (
    <div className="root-component">
      <h1>
        ViewEngine:
        <span className={`roadname roadname--${road.toLowerCase()}`} style={{ textTransform: 'uppercase' }}>
          {road}
          {engine}
        </span>
        <a
          className="mini-link"
          href="photos"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/view-engine/${road.toLowerCase()}/${engine}/photos`);
            return false;
          }}
        >
          View All Photos
        </a>
      </h1>
      {!hasAltId && (
        <form id="alt_id_form" action="/api/save-alt-id" method="post" onSubmit={handleSubmit}>
          <label htmlFor="alt_id">
            Set Alternate ID:
            <input name="alt_id" id="alt_id" type="text" />
          </label>
          <input type="submit" value="Save" />
          <input type="hidden" value={engine} name="engine" />
          <input type="hidden" value={road} name="road" />
        </form>
      )}
      <div className="engine-sightings">
        {sightingsToShow &&
          sightingsToShow.map((key, index) => {
            const sighting = key;
            const sightingLoaded = sighting && sighting.pictures && sighting.pictures.length > 0;
            let doLoad = false;
            if ((!sightingLoaded && !oneToLoad) || index === indexToLoad) {
              doLoad = true;
              oneToLoad = true;
            }
            return (
              <Sighting
                sightingLoaded={sightingLoaded}
                sightingId={sighting.id}
                className="engine-sighting"
                key={sighting.id}
                showLocation
                image
                onSightingLoad={onSightingLoad}
                engineCount
                doLoad={doLoad}
              />
            );
          })}
      </div>
      <div className="previous-sightings">
        <div>
          {pictures &&
            pictures.map((source) =>
              source.urls.map((url: string, index) => (
                <div key={url} className="single-previous-sighting">
                  <div className="single-previous-sighting__img-wrap">
                    <img src={`${url.indexOf('http') < 0 ? 'https://railpictures.net' : ''}${url}`} alt="Engine" />
                  </div>
                  {source.locations[index]} {source.dates[index]} {source.models[index]}
                </div>
              ))
            )}
        </div>
      </div>
    </div>
  );
};

// ViewEngine.defaultProps = {
//   engine: null,
//   road: null,
// };

ViewEngine.propTypes = {
  engine: PropTypes.string.isRequired,
  road: PropTypes.string.isRequired,
};

export default ViewEngine;
