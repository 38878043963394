import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import EngineTag from './EngineTag';
import RoadNameNumberComboBox from './RoadNameNumberComboBox';
import { getSearchResults } from '../actions';
import IRoad from '../interfaces/IRoad';

type FindEngineProps = {
  roads: Array<IRoad>;
};

const FindEngine: React.FC<FindEngineProps> = props => {
  const dispatch = useDispatch();
  const searchResults = useSelector(state => state.searchResults);
  const [road, setRoad] = useState(null);
  const [number, setNumber] = useState(null);
  useEffect(() => {
    if (road !== null || number !== null) {
      dispatch(getSearchResults(road, number));
    }
  }, [road, number, dispatch]);
  const { roads } = props;

  return (
    <div className="view-sighting root-component">
      FindEngine
      <RoadNameNumberComboBox
        roads={roads}
        testValidity={false}
        additionalOnChange={(value, type) => (type === 'road' ? setRoad(value) : setNumber(value))}
      />
      <p>Possible Engines ({searchResults.length})</p>
      <div className="engine-tag-grid" style={{display:'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
      {searchResults.map(obj => {
        return (
          <div key={obj.id}>
            <EngineTag
              road={roads.filter((road) => {
                return obj.road === road.id;
              })[0].short_name
              }
              big
              short
              num={obj.num} small />
          </div>
        );
      })}
      </div>
    </div>
  );
};
FindEngine.defaultProps = {
  roads: [],
};
FindEngine.propTypes = {
  roads: PropTypes.array,
};
export default FindEngine;
