import React from 'react';
import PropTypes from 'prop-types';

const TextSlant = props => {
  const letters = [];
  const { text } = props;
  text.split('').forEach((letter, index) => {
    const key = `${letter}-${index}`;
    if (letter === ' ' || letter === '') {
      letters.push(
        <span key={key} className="letter">
          &nbsp;
        </span>
      );
    } else {
      const val = `${(index * 0.05).toString()}s`;
      letters.push(
        <span style={{ transitionDelay: val }} key={key} className="letter">
          {letter}
        </span>
      );
    }
  });
  setTimeout(() => {
    const res = Array.from(document.querySelectorAll('.letter'));
    if(res && res.forEach) {
      res.forEach(el => el.classList.add('ready'));
    }
  }, 150);
  const styles = { margin: '0', position: 'absolute', top: '0', left: '0' };
  return (
    <h1 className="big big-font" style={styles}>
      {letters}
      <style jsx global>{`
        .letter {
            opacity: 0;
            position: relative;
            overflow;
            display: inline-block;
             -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=);
              background-repeat: no-repeat;
              background-position: -100px -100px;
              background-size: 100% 100%;
              filter: blur(2px);
              transition: filter .3s ease-out, opacity .4s ease-out, background-position .3s ease-out;
              will-change: filter, opacity, background-position;
          }



          .letter.ready {
            filter: none;
           opacity: 1;
           background-position: 0 0;
          }
        `}</style>
      <style jsx>{`
        .big {
          font-weight: 700;
          font-family: 'Roboto Condensed', sans-serif;
          text-transform: uppercase;
        }

        .big-font {
          font-size: 6rem;
        }
      `}</style>
    </h1>
  );
};

TextSlant.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TextSlant;
