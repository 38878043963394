import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import NumberAnimation from './NumberAnimation';
import NumberAnimationAlt from './NumberAnimationAlt';
import TextSlant from './TextSlant';
import Utils from './Utils';
import ILocation from '../interfaces/ILocation';
import DateFromTS from './DateFromTS';
import { getLocations } from '../actions';
import ISighting from '../interfaces/ISighting';

type ViewLocationProps = {
  location: ILocation,
  locationSlug: string,
  getLocations?:Function,
};

type ViewLocationState = {
  extraInfo: Array<ISighting>,
}
class ViewLocation extends React.Component<ViewLocationProps, ViewLocationState> {
  static defaultProps: ViewLocationProps;

  static propTypes: any;

  constructor(props) {
    super(props);
    this.state = {
      extraInfo: null,
    };
  }

  componentDidMount() {
    const { location, locationSlug, getLocations } = this.props;
    const url = `${Utils.Host}/api/get-first-and-last-for-location?location=${location.id}&locationSlug=${locationSlug}`;
    axios.get(url).then((json) => {
      const extraInfo = json.data;
      if (extraInfo.length === 1) {
        extraInfo.push(extraInfo[0]);
      }

      this.setState({
        extraInfo,
      });
    });
    if (Object.keys(location).length === 0) {
      getLocations();
    }
  }

  render() {
    const ib = {
      display: 'inline-block',
    };
    const { extraInfo } = this.state;
    const { location, locationSlug } = this.props;
    const letters = [];
    if (location && location.name) {
      location.name.split('').forEach((letter, index) => {
        const key = `${letter}-${index}`;
        let content = letter;
        if (letter === '' || letter === ' ') {
          content = '\u00A0';
        }
        letters.push(
          <span key={key} style={ib}>
            {content}
          </span>,
        );
      });
    } else {
      locationSlug.split('').map(() => letters.push(<span>-</span>));
    }

    const animation = Math.round(Math.random()) ? (
      <NumberAnimation start={0} end={location.sightings} />
    ) : (
      <NumberAnimationAlt start={0} end={location.sightings} />
    );
    return (
      <div className="root-component">
        <div style={{ position: 'relative', overflow: 'hidden', lineHeight: '4.7em' }} className="">
          <h1 style={{ margin: '0' }} className="big big-font outline-me">
            {letters}
          </h1>
          {location && location.name && <TextSlant text={location.name} />}
        </div>
        <div className="flex big">
          {animation}
          <div style={{ marginBottom: '22px' }}>Sightings</div>
          {extraInfo && (
            <div className="big dates">
              <div className="date">
                First Sighting:
                {' '}
                <DateFromTS ts={extraInfo[0].ts} id={extraInfo[0].id} />
              </div>
              <div className="date">
                Last Sighting:
                {' '}
                <DateFromTS ts={extraInfo[1].ts} id={extraInfo[1].id} />
              </div>
            </div>
          )}
        </div>
        <style jsx>
          {`
          .outline-me {
            text-shadow: 0px 0px 1px #000;
            color: rgba(255, 255, 255, 1);
          }
          h1 span {
            display: inline-block;
          }
          .dates {
            display: flex;
            align-self: flex-start;
            font-size: 2.65rem;
            margin-top: 14px;
            flex-wrap: wrap;
            padding-left: 20px;
          }
          .date {
            flex: 0 0 100%;
          }
          .date:first-child {
            transform: translateX(-30px);
          }
          .big {
            font-weight: 700;
            font-family: 'Roboto Condensed', sans-serif;
            text-transform: uppercase;
          }
          .big-font {
            font-size: 6rem;
          }
          .flex {
            display: flex;
            align-items: flex-end;
          }
        `}
        </style>
      </div>
    );
  }
}

ViewLocation.defaultProps = {
  location: null,
  locationSlug: null,
};

ViewLocation.propTypes = {
  location: PropTypes.object,
  locationSlug: PropTypes.string,
  getLocations: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const { locationSlug } = props;
  const { locations } = state;
  let location = {};

  const res = Utils.getLocationFromSlug(locationSlug, locations);

  if (res) {
    location = res;
  }

  return {
    location,
  };
};

export default connect(
  mapStateToProps,
  { getLocations },
)(ViewLocation);
