import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Blurhash } from 'react-blurhash';
import TrainPicture from './TrainPicture';
import EngineTag from './EngineTag';
import SightingTime from './SightingTime';
import ISighting from '../interfaces/ISighting';
import IAppState from '../interfaces/IAppState';
import Utils from './Utils';
import { getSighting } from '../actions';
import ILocation from '../interfaces/ILocation';
import IAppSettings from '../interfaces/IAppSettings';

type DataBlockProps = {
  value: string | number | undefined;
  label: string;
};

const DataBlock: React.FC<DataBlockProps> = (props) => {
  const { label, value } = props;
  return (
    <div style={{ fontSize: '1.5rem', textAlign: 'center', padding: '0 5px', flex: '0 0 auto' }}>
      <p style={{margin:'0'}}>
        {value}
        <span style={{ display: 'block', margin: '0 auto' }}>{label}</span>
      </p>
    </div>
  );
};

DataBlock.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

type SightingProps = {
  sightingId?: number;
  sighting: ISighting | string;
  location: any;
  colorMode?: string;
  getLocations?: Function;
  getSighting?: Function;
  removeImageFromSighting: Function;
  roads: Array<string>;
  nextSighting: number,
  prevSighting: number,
};
// interface IViewMode {
//   name: string;
//   label: string;
// }
// type SightingState = {
//   loading: boolean;
//   activeRoad?: any;
//   activeEngine?: any;
//   currentImage: any;
//   viewMode: string;
//   viewModes: Array<IViewMode>;
// };
export const ViewSighting: React.FC<SightingProps> = (props) => {
  const browserLocation = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const sighting: (ISighting | string) = useSelector(
    (state: IAppState) => state.sightings.sightingsByLocation.all[props.sightingId]
  );
  const [justHash, setJustHash] = useState(false);
  const [showDebug, setShowDebug] = useState(false);
  const location: ILocation = useSelector((state: IAppState) => {
    if (!state.locations || !sighting || !state.locations[sighting.location]) {
      return null;
    }
    return state.locations[sighting.location];
  });

  if ((!sighting || !sighting.pictures) && sighting !== 'loading') {
    dispatch(getSighting(props.sightingId, location === null));
  }
  const [currentImage, setCurrentImage] = useState(null);



  const enlargeImage = (currentImageProp) => {
    setCurrentImage(currentImageProp);
  };

  const removeImage = (imageId) => {
    const { sightingId, removeImageFromSighting } = props;
    removeImageFromSighting(imageId, sightingId);
  };

  const finish = () => {
    const { sightingId } = props;
    const dataString = `stamp=${window.stamp}&sighting=${sightingId}&ts=${window.tsString}`;
    const oReq = new XMLHttpRequest();
    oReq.open('POST', '/api/finish-sighting', true);
    oReq.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    oReq.onload = () => {
      // if (oReq.status === 200 && oReq.readyState === 4) {}
    };
    oReq.send(dataString);
    return false;
  };

  const settings: IAppSettings = useSelector((appState: IAppState) => appState.settings);
  const { colorMode } = settings;

  const roads = useSelector((state: IAppState) => state.roads);
  const viewModes = [
    { name: 'grid', label: 'Grid' },
    { name: 'slideshow', label: 'Slideshow' },
  ];
  const [viewMode, setViewMode] = useState('grid');

  const [activeEngineId, setActiveEngineId] = useState(null);



  sighting &&
    sighting !== 'loading' &&
    sighting.pictures &&
    sighting.pictures.sort((a, b) => {
      const aD = new Date(a.timestamp);
      const bD = new Date(b.timestamp);
      if (aD.getTime() < bD.getTime()) {
        return -1;
      }
      if (aD.getTime() === bD.getTime()) {
        return 0;
      }
      return 1;
    });
  let totalTime = 0;
  let avgTOS = 0;
  sighting &&
    sighting.pictures &&
    sighting.pictures.forEach((pic) => {
      const halves = pic.ts.split('T');
      const firstSplit = halves[0].split('-');
      const dateString = `${firstSplit[1]}/${firstSplit[2]}/${firstSplit[0]} ${halves[1]}`;
      const fd = new Date(dateString);
      if (isNaN(fd.getTime())) {
        fd.setUTCFullYear(firstSplit[0]);
        fd.setUTCMonth(firstSplit[1] - 1);
        fd.setUTCDate(firstSplit[2]);
        const tPieces = halves[1].split(':');
        fd.setUTCMinutes(tPieces[1]);
        fd.setUTCSeconds(tPieces[2].split('.')[0]);
        fd.setUTCHours(tPieces[0]);
      }
      totalTime += fd.getTime();
      avgTOS += fd.getTimezoneOffset();
    });

  if (sighting && sighting.pictures) {
    avgTOS /= sighting.pictures.length;
  }

  let finishButton: HTMLButtonElement = (
    <button type="button" onClick={finish}>
      &#8220;Finish&#8221; Sighting
    </button>
  );

  if (sighting && sighting.ts && sighting.ts !== '0000-00-00 00:00:00') {
    finishButton = null;
  }

  const toggleButtons = [];

  viewModes.map((mode) => {
    let cn = 'view-sighting__toggle-button';
    const kk = `view-toggle-${mode.name}`;

    if (mode.name === viewMode) {
      cn += 'view-sighting__toggle-button--active';
      return toggleButtons.push(
        <>&nbsp;<button type="button" disabled key={kk} data-mode={mode.name} className={cn}>
          {mode.label}
        </button></>
      );
    }
    return toggleButtons.push(
      <>&nbsp;<button
        onClick={(e) => {
          e.preventDefault();
          setViewMode(mode.name);
          return false;
        }}
        type="button"
        key={kk}
        data-mode={mode.name}
        className={cn}
      >
        {mode.label}
      </button></>
    );
  });

  let modal: HTMLModElement = null;

  if (currentImage) {
    const pic =
      sighting &&
      sighting.pictures &&
      sighting.pictures.filter((picInLoop) => picInLoop.id.toString() === currentImage.toString())[0];
    modal = (
      <dialog open className="view-sighting__modal">
        <button
          type="button"
          className="close-button"
          onClick={() => {
            setCurrentImage(null);
          }}
          aria-label="Close Modal"
        >
          Close
        </button>
        <div className="view-sighting__modal-inner">
          <div className="view-sighting__modal-content">
            <img
              sizes="80vw"
              alt="Main Train"
              onError={(e) => {
                e.currentTarget.setAttribute(
                  'srcset',
                  Utils.getSrcSet({
                    filename: pic.filename,
                    errored: true,
                  })
                );
              }}
              srcSet={Utils.getSrcSet({
                filename: pic.filename,
              })}
            />
          </div>
        </div>
      </dialog>
    );
  }

  let pictureToUse = sighting && sighting.pictures && sighting.pictures[0];
  let foundOne = false;
  if (pictureToUse && !pictureToUse.blurhash) {
    sighting.pictures.forEach((pic) => {
      const { blurhash } = pic;
      if (!foundOne && blurhash) {
        foundOne = true;
        pictureToUse = pic;
      }
    });
  }
  const { nextSighting, prevSighting } = sighting || {nextSighting: null, prevSighting: null};
  const keypressFunc = (e) => {
    e = e || window.event;
    const { key } = e;
    if (key === 'x' || key === 'X' || key === 'Escape') {
      setCurrentImage(null);
    } else if (e.keyCode == '37') {
      // left arrow
      history.push(`/view-sighting/${prevSighting}`)
    } else if (e.keyCode == '39') {
      // right arrow
      history.push(`/view-sighting/${nextSighting}`)
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', keypressFunc);
    return () => {
      window.removeEventListener('keyup', keypressFunc)
    }
  }, [currentImage, nextSighting, prevSighting, keypressFunc]);


  return (
    <div>
      <div className="view-sighting root-component">
        <div>
          {pictureToUse && pictureToUse.blurhash && (
            <Blurhash
              hash={pictureToUse.blurhash}
              width={window.innerWidth}
              height={window.innerHeight}
              resolutionX={32}
              resolutionY={32}
              punch={1}
              className="view-sighting__background view-sighting__background--done view-sighting__background--blurhash"
              style={{ transition: 'none' }}
            />
          )}
          {/* {pictureToUse && (
            <img
              sizes="100vw"
              alt="Background Train"
              className="view-sighting__background"
              load="lazy"
              onLoad={(e) => {
                //e.currentTarget.classList.add('view-sighting__background--done');
              }}
              srcSet={Utils.getSrcSet({
                filename: pictureToUse.filename,
              })}
            />
          )} */}
        </div>
        <div className="view-sighting__content">
          <div className={`view-sighting__copy view-sighting__copy--${colorMode}`}>
            <h1 className="view-sighting__headline">
              {location && location.name}
              {!location && sighting && sighting.location_name}
              &nbsp;
              {sighting && sighting.ts && sighting.ts !== '0000-00-00 00:00:00' && (
                <SightingTime id="1"  string={sighting.ts} newStyle />
              )}
              {sighting && (sighting.ts === null || sighting.ts === '0000-00-00 00:00:00') && (
                <SightingTime
                  totalTime={totalTime}
                  id="2"
                  showDayOfWeek
                  length={sighting && sighting.pictures && sighting.pictures.length}
                  avgTOS={avgTOS}
                />
              )}

            </h1>
            {sighting && showDebug && (
            <div>
              {new Date(sighting.local_time).toLocaleDateString()}<br/>
              {new Date(sighting.local_time).toLocaleTimeString()} Time Where Picture Was Taken<br/>
              {new Date(sighting.local_time).toLocaleTimeString('en-US', { timeZone: sighting.timezone})} Time Where I Am<br/>
              {new Date(sighting.local_time).toLocaleTimeString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})} Time Where I Am<br/>
            </div>
            )}
            {finishButton}
            <div className='view-sighting__view-toggles'>
              View Mode:{toggleButtons}
            </div>
            <div style={{flex:'0 0 100%'}}>
              {browserLocation.pathname.indexOf('cacheBust=true') < 0 && (
                <a
                  href={`${browserLocation.pathname}?cacheBust=true`}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`${browserLocation.pathname}?cacheBust=true`);
                  }}
                >
                  Bust Cache
                </a>
              )}
              <div>
                Just Blur Hash?
                <input
                  type="checkbox"
                  value={justHash}
                  onChange={()=> {
                    setJustHash(!justHash);
                  }}
                />
              </div>
            </div>



            <div className="engines" style={{minWidth: '50%', alignItems: 'center'}}>
              {sighting &&
                sighting.engines &&
                sighting.engines.map((obj) => {
                  let road = roads[obj.road];
                  if (road && road.id) {
                    road = roads.filter((roadInLoop) => roadInLoop.id === obj.road)[0].short_name;
                  }

                  const r = roads ? roads.filter((roadInLoop) => roadInLoop.id === obj.road)[0] : null;
                  return (
                    <div key={`et-${obj.id ? obj.id : obj.locomotive_id}`}>
                      {roads && roads.filter((roadInLoop) => roadInLoop.id === obj.road)[0] && (
                        <EngineTag
                          road={r.short_name}
                          num={obj.num}
                          short
                          small
                          onMouseOverFunc={() => {
                            setActiveEngineId(obj.locomotive_id);
                          }}
                          onMouseOutFunc={() => {
                            setActiveEngineId(null);
                          }}
                        />
                      )}
                    </div>
                  );
              })}
            </div>
            <div style={{ flex: '0 0 auto;', minWidth:'50%', justifyContent: 'flex-end', display:'flex', textAlign: 'right', marginRight: '0', justifySelf:'right', alignSelf:'right' }}>
              {
                sighting && sighting.engines && Array.from(sighting.engines).length && (
                  <DataBlock value={sighting && sighting.engines && Array.from(sighting.engines).length} label="Engines" />
                )
              }
              {
                sighting && sighting.pictures && sighting.pictures.length && (
                  <DataBlock value={sighting && sighting.pictures && sighting.pictures.length} label="pictures" />
                )
              }
            </div>
          </div>
          <div
            className={`pictures pictures--on-sighting ${
              viewMode === 'slideshow' ? 'pictures--on-sighting-slideshow' : ''
            } `}
          >
            {sighting &&
              sighting.pictures &&
              sighting.pictures.map(({ rotated, sized, filename, id, engines, ts, blurhash }, index) => (
                <TrainPicture
                  fullWidth={viewMode === 'slideshow'}
                  rotated={rotated === 1}
                  sized={sized === 1}
                  enlargeImage={enlargeImage}
                  engineData={sighting.engines}
                  roadNames={roads}
                  enginesInPic={engines}
                  removeImage={removeImage}
                  timestamp={ts}
                  blurhash={blurhash}
                  activeEngineId={activeEngineId}
                  key={id}
                  justHash={justHash}
                  filename={filename}
                  imageId={id.toString()}
                  id={id}
                  index={index}
                />
            ))}
          </div>
        </div>
      </div>
      {modal}
      <style jsx global>
        {`
            .view-sighting__headline {
              min-width: 50%;
              font-family: 'Roboto', sans-serif;
              font-size:1.875rem;
              margin:0;
              display:flex;
              align-items:center;
              color:#001E3C;
            }
            .view-sighting__view-toggles {
              margin-right: 0;
              justify-self: flex-end;
              align-self: flex-end;
              min-width: 50%;
              text-align:right;
            }
            .view-sighting__copy {
              background:linear-gradient(to right bottom, rgb(0, 127, 255), rgb(0, 89, 178) 120%);
border:0;
              box-shadow: 0px 0px 3px 1px rgb(0 0 255 / 50%) inset
            }
            .close-button {
              border: 0;
              background: transparent;
              width: 80px;
              height: 80px;
              position: fixed;
              top: 20px;
              right: 80px;
              background: white;
              color: black;
              z-index: 1003;
            }
            .pictures--on-sighting-slideshow {
              flex-wrap:nowrap;
              overflow:auto;
              -webkit-overflow-scrolling: touch;
              scroll-behavior: smooth;
              scroll-snap-type: x mandatory;
            }
            .pictures--on-sighting-slideshow::-webkit-scrollbar { width: 0 !important;height: 0 !important };
            }
          `}
      </style>
    </div>
  );
};

ViewSighting.defaultProps = {
  removeImageFromSighting: null,
};

ViewSighting.propTypes = {
  sightingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  removeImageFromSighting: PropTypes.func,
};

export default ViewSighting;
