import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IAppState from 'interfaces/IAppState';
import IAppSettings from 'interfaces/IAppSettings';
import { setColorMode } from '../actions';
import Utils from './Utils';

const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const MainMenu: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goToView = (event, e) => {
    e.preventDefault();
    history.push(event);
    return false;
  };
  const settings: IAppSettings = useSelector((appState: IAppState) => appState.settings);
  const { colorMode } = settings;
  const subscriptionSaved = (e) => {
    console.log('subscription saved');
  }

  return (
    <nav className={`main-menu main-menu--${colorMode}`}>
      <ul className="main-menu__list">
        <li className="main-menu__item">
          <a
            type="button"
            href="/view-locations"
            className="main-menu__link"
            onClick={(e) => {
              goToView('/view-locations', e);
            }}
          >
            View Locations
          </a>
        </li>
        {/* <li className="main-menu__item">
          <button className="main-menu__link" onClick={() => this.goToView('/add-manual-sighting')}>Add Manual Sighting</button></li> */}
        <li className="main-menu__item">
          <a
            type="button"
            href="/find-engine"
            className="main-menu__link"
            onClick={(e) => {
              goToView('/find-engine', e);
            }}
          >
            Find Engine
          </a>
        </li>
        <li className="main-menu__item">
          <a
            type="button"
            className="main-menu__link"
            href="/view-sightings"
            onClick={(e) => {
              goToView('/view-sightings', e);
            }}
          >
            View Sightings
          </a>
        </li>
        <li className="main-menu__item">
          <a
            href="/view-engines"
            type="button"
            className="main-menu__link"
            onClick={(e) => {
              goToView('/view-engines', e);
            }}
          >
            View Engines
          </a>
        </li>
      </ul>
      <div className="subnav">
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            Notification.requestPermission().then(async (result) => {
              if (window.swRegistration) {
                // EVENTUALLY WE WILL WANT TO MOVE TO .ENV FILES
                const applicationServerKey = urlB64ToUint8Array(
                  'BJ1-Glu7hoUbivLHVP9ee__m-NlDHO8p4aJH-6lBhcNUTQtnxNjDBXE0IkdhzJm7xrc4SnwggIyxq4kMdW1lpQc'
                );
                const options = {
                  applicationServerKey,
                  userVisibleOnly: true,
                };
                const subscription = await window.swRegistration.pushManager.subscribe(options);
                const fd = new FormData();
                fd.append('json', JSON.stringify(subscription));
                const oReq = new XMLHttpRequest();
                oReq.open('POST', '/api/save-subscription', true);
                oReq.addEventListener('load', subscriptionSaved);
                console.dir(fd);
                oReq.send(fd);
                return false;
              }
            });
            return false;
          }}
        >
          Subscribe
        </button>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            dispatch(setColorMode(Utils.getOppositeColorMode(colorMode)));
            return false;
          }}
        >
          Set Mode
        </button>
      </div>
    </nav>
  );
};

MainMenu.propTypes = {};

export default MainMenu;
