import axios from 'axios';
import Utils from '../components/Utils';

export const GET_LOCATIONS = 'get_locations';
export const GET_SIGHTINGS = 'get_sightings';
export const GET_SIGHTING = 'get_sighting';
export const GET_ENGINES = 'get_engines';
export const GET_PICTURES = 'get_pictures';
export const SET_ALT_ID = 'set_alt_id';
export const GET_SEARCH_RESULTS = 'get_search_results';
export const SET_COLOR_MODE = 'set_color_mode';
export const GET_MOST_SEEN_ENGINES = 'get_most_seen_engines';
export const GET_NEWEST_ENGINES = 'get_newest_engines';
export const GET_RECENTLY_SEEN_ENGINES = 'get_recently_seen_engines';
export const GET_ROADS = 'get_roads';
export const GET_SIGHTING_CALL = 'get_sighting_call'; //on start
export const GET_ALL_ENGINE_PICTURES = 'get_all_engine_pictures';

export const ActionTypes = {
  MakeImageHash: 'MAKE_IMAGE_HASH',
  CreateBlurHashSuccess: 'CREATE_BLUR_HASH_SUCCESS',
  SaveBlurHashSuccess: 'SAVE_BLUR_HASH_SUCCESS',
  GetStats: 'GET_STATS',
  GotOnThisDay: 'GOT_ON_THIS_DAY',
};

export const getOnThisDay = () => (dispatch) => {
  const url = `${Utils.Host}/api/get-on-this-day`;
  axios.get(url).then(json => {
    return dispatch({
      type: ActionTypes.GotOnThisDay,
      payload: json.data,
    });
  });
}

export const getRoads = () => (dispatch) => {
  const url = `${Utils.Host}/api/get-roads`;
  axios.get(url).then(json => {
    dispatch({
      type: GET_ROADS,
      payload: json.data,
    });
  });
};

export const createGetStatsAction = () => (dispatch) => {
  const url = `${Utils.Host}/api/get-stats`;
  axios.get(url).then(json => {
    return dispatch({
      type: ActionTypes.GetStats,
      payload: json.data,
    });
  });
};

export const createMakeImageHashEvent = (id, alternate = false) => (dispatch) => {
  const url = `${Utils.Host}/api/create-blur-hash/${id}`;
  axios.post(url, {alternate}).then(json => {
    return dispatch({
      type: ActionTypes.CreateBlurHashSuccess,
      payload: {
        imageData: json.data,
        id: parseInt(id, 10),
      },
    });
  });
};

export const createSaveImageHashEvent = (id, hash) => (dispatch) => {
  const url = `${Utils.Host}/api/save-blur-hash/${id}`;
  axios.post(url, {hash}).then(json => {
    dispatch({
      type: ActionTypes.SaveBlurHashSuccess,
      payload: json.data,
    });
  });
};

export const getRecentlySeenEngines = () => dispatch => {
  const url = `${Utils.Host}/api/get-recently-seen-engines`;
  axios.get(url).then(json => {
    dispatch({
      type: GET_RECENTLY_SEEN_ENGINES,
      payload: json.data,
    });
  });
};
export const getNewestEngines = () => dispatch => {
  const url = `${Utils.Host}/api/get-engines?order=desc`;
  axios.get(url).then((json) => {
    dispatch({
      type: GET_NEWEST_ENGINES,
      payload: json.data.engines,
    });
  });
};

export const getMostSeenEngines = () => dispatch => {
  const url = `${Utils.Host}/api/get-most-seen-engines`;
  axios.get(url).then((json) => {
    dispatch({
      type: GET_MOST_SEEN_ENGINES,
      payload: json.data,
    });
  });
};

export const setColorMode = mode => dispatch => {
  dispatch({
    type: SET_COLOR_MODE,
    payload: mode,
  });
};

export const getSearchResults = (road, engine) => async dispatch => {
  const url = `${Utils.Host}/api/get-engines?mode=search&road=${road}&engine=${engine}`;
  axios.get(url).then(json => {
    dispatch({
      type: GET_SEARCH_RESULTS,
      payload: json.data.engines,
    });
  });
};

export const setAltId = (road, engine, altId) => dispatch => {
  dispatch({
    type: SET_ALT_ID,
    payload: {
      road,
      engine,
      altId,
    },
  });
};

export const getAllEnginePhotos = (road, engine) => async dispatch => {
  const url = `${Utils.Host}/api/get-all-pictures?road=${road}&engine=${engine}`;
  const resp = caches.match(url).then((respObj) => {
    if (respObj && respObj.data !== null && respObj.data !== undefined) {
      return respObj.data;
    }
    axios.get(url).then(json => {
      dispatch({
        type: GET_ALL_ENGINE_PICTURES,
        payload: json.data,
      });
    });
  });

  return {
    type: GET_ALL_ENGINE_PICTURES,
    payload: resp.data || [],
  };
};

export const getPictures = (road, engine) => async dispatch => {
  const url = `${Utils.Host}/api/get-railpictures?road=${road}&engine=${engine}`;
  const resp = caches.match(url).then((respObj) => {
    if (respObj && respObj.data !== null && respObj.data !== undefined) {
      return respObj.data;
    }
    axios.get(url).then(json => {
      dispatch({
        type: GET_PICTURES,
        payload: json.data,
      });
    });
  });

  return {
    type: GET_PICTURES,
    payload: resp.data || [],
  };
};

export const getLocations = () => async dispatch => {
  const url = `${Utils.Host}/api/get-locations`;
  const resp = await caches.match(url).then(respObj => {
    return respObj || axios.get(url);
  });
  dispatch({
    type: GET_LOCATIONS,
    payload: resp.data,
  });
};

export const getSighting = (sightingId, getLocName = false) => async dispatch => {
  dispatch({
    type: GET_SIGHTING_CALL,
    payload: sightingId,
  });
  const url = `${Utils.Host}/api/get-sighting/${sightingId}${getLocName ? '?loc_name=true' : ''}`;
  const resp = caches.match(url).then(respObj => {
    if (respObj && respObj.data !== null && respObj.data !== undefined) {
      return respObj.data;
    }
    axios.get(url).then(json => {
      dispatch({
        type: GET_SIGHTING,
        payload: json.data.sighting,
      });
    });
  });

  return {
    type: GET_SIGHTING,
    payload: resp.data || [],
  };
};

export const getEngines = (page, roadToUse = null) => async dispatch => {
  const url = `${Utils.Host}/api/get-engines?page=${page}${roadToUse ? `&road=${roadToUse}` : ''}`;
  const resp = caches.match(url).then(respObj => {
    if (respObj && respObj.data !== null && respObj.data !== undefined) {
      return resp.data;
    }
    axios.get(url).then(json => {
      dispatch({
        type: GET_ENGINES,
        payload: json.data,
      });
    });
  });

  return {
    type: GET_ENGINES,
    payload: resp.data || [],
  };
};

// export const createStream = (formValues) => async (dispatch) => {
export const getSightings = (page, locationId = null, road = null, engine = null) => async dispatch => {
  let url = `/api/get-sightings?page=${page - 1}${locationId ? `&location=${locationId}` : ''}`;
  url += `${road ? `&road=${road}` : ''}`;
  url += `${engine ? `&engine=${engine}` : ''}`;
  const resp = caches.match(url).then(respObj => {
    if (respObj && respObj.data !== null && respObj.data !== undefined) {
      return resp.data;
    }
    axios.get(url).then(json => {
      dispatch({
        type: GET_SIGHTINGS,
        payload: json.data,
      });
    });
  });

  return {
    type: GET_SIGHTINGS,
    payload: resp.data || [],
  };
};
