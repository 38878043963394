import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import IRoad from 'interfaces/IRoad';
import IAppState from 'interfaces/IAppState';
import IEngine from 'interfaces/IEngine';
import EngineTag from './EngineTag';
import { getMostSeenEngines } from '../actions';

const MostViewedEngines: React.FC = () => {
  const dispatch = useDispatch();
  const roads: Array<IRoad> = useSelector((state: IAppState) => state.roads);
  const data: Array<IEngine> = useSelector((state: IAppState) => state.engines.mostSeenEngines);
  if (!data || data.length === 0) {
    dispatch(getMostSeenEngines());
  }

  return (
    <div className="engine-listing__list">
      <h2 className="full">Most View Engines:</h2>
      <ul className="plain-list plain-list--stack">
        {roads &&
          data &&
          data.map((engine) => {
            const roadObj = roads.filter((roadParam) => roadParam.id === engine.road)[0];
            const { short_name } = roadObj;
            const { num, count, road } = engine;
            return road ? (
              <li key={`${engine.road}${num}`} className="single-engine single-engine--in-list">
                <EngineTag inList road={short_name} num={num} short /> ({count})
              </li>
            ) : null;
          })}
      </ul>
    </div>
  );
};

MostViewedEngines.defaultProps = {};
MostViewedEngines.propTypes = {};

export default MostViewedEngines;
