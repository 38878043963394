import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import IRoad from 'interfaces/IRoad';
import IAppState from 'interfaces/IAppState';
import ISighting from 'interfaces/ISighting';
import Sighting from './Sighting';
import Utils from './Utils';
import { getOnThisDay, getLocations } from '../actions';

const OnThisDay: React.FC = (props) => {
  const dispatch = useDispatch();
  const roads: Array<IRoad> = useSelector((state: IAppState) => state.roads);
  const data: Array<ISighting> = useSelector((state: IAppState) => state.sightings.onThisDay);
  const locations: Array<ILocation> = useSelector((appState: IAppState) => appState.locations);

  const getLocationForSighting = (locationId) => {
    return locations[locationId.toString()];
  };

  if (!locations || Object.keys(locations).length === 0) {
    dispatch(getLocations());
  }

  if (data === null) {
    dispatch(getOnThisDay());
  }
  return (
    <div className="engine-listing__list">
      <h2 className="full">On This Day: {data && data.length > 0 ? `${data.length}` : 'Check Back Tomorrow!'}</h2>
      <ul className="plain-list plain-list--stack">

        {roads &&
          data &&
          data.map((sighting) => {
            const loc = getLocationForSighting(sighting.location);
            console.dir(sighting);
            return (
               <Sighting
                 showLocation
                 className="sightings-listing__listing"
                 sightingId={sighting.id}
                 location={loc}
                 sightingObj={sighting}
                 locationSlug={loc && Utils.slugify(loc.name)}
                 key={sighting.id}
                 noPics
                 showLocation
               />
            );
          })}
      </ul>
    </div>
  );
};

OnThisDay.defaultProps = {};
OnThisDay.propTypes = {};

export default OnThisDay;
