class AJAXCall {
  resolveFunc() {
    console.log('resolve');
  }

  rejectFunc() {
    console.log('reject');
  }

  cancel() {
    delete window[this.callbackName];
    try {
      document.body.removeChild(this.appendedEl);
    } catch (e) {
      // unable to remove
    }
  }

  constructor(url, callback) {
    this.cancel = this.cancel.bind(this);
    this.callbackName = `jsonp_callback_${Math.round(100000 * Math.random())}`;

    const script = document.createElement('script');
    script.src = `${url}${url.indexOf('?') >= 0 ? '&' : '?'}callback=${this.callbackName}`;

    this.appendedEl = document.body.appendChild(script);
    window[this.callbackName] = (data) => {
      delete window[this.callbackName];
      document.body.removeChild(script);
      callback(data);
    };

    /* this.promise = new Promise ((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);

    xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
            resolve(xhr.response);
        } else {
            reject(xhr.statusText);
        }
    };
    xhr.onerror = () => reject(xhr.statusText);
    xhr.send();//obj.body);
    }); */
  }

  then() {
    return this.promise.then.apply(this.promise, arguments);
  }
  /* constructor(resolve, reject, url) {
  super(resolve, reject);
  } */
}

export default AJAXCall;
