import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IEngine from 'interfaces/IEngine';
import IRoad from 'interfaces/IRoad';
import Utils from './Utils';

type RoadNameNumberComboBoxProps = {
  roads: Array<IRoad>;
  name: string;
  additionalOnChange: Function;
  testValidity: boolean;
  engineData: IEngine;
  engines: Object;
  setRoadNameNumberComboBoxValidity: Function;
};

const RoadNameNumberComboBox: React.FC<RoadNameNumberComboBoxProps> = (props) => {
  const { roads, name, additionalOnChange, testValidity, engineData } = props;
  const [state, setState] = useState({
    engine: '',
    road: '',
    blankRoad: !engineData || !engineData.road || engineData.road.toString().length === 0,
    blankEngine: !engineData || !engineData.num || engineData.num.toString().length === 0,
    possibleEngines: [],
  });

  const setRoad = (road) => {
    const { engines } = props;
    const newPossibleEngines =
      engines &&
      Object.keys(engines)
        .map((k) => engines[k])
        .filter((eid) => eid.road === road);
    setState({
      ...state,
      road,
      possibleEngines: newPossibleEngines,
      blankRoad: false,
    });
    // TODO: use a datasource to autofill
    tellValidity('road', road);
  };

  const setEngine = (engineParam) => {
    const engineValue = engineParam.length > 4 ? parseInt(engineParam.toString().substr(0, 4), 10) : engineParam;
    setState({
      ...state,
      engine: engineValue,
      blankEngine: false,
    });
    tellValidity('engine', engineValue);
  };

  const blank = () => {
    const { blankEngine, blankRoad } = state;
    if (props.engineData && (props.engineData.road || props.engineData.num)) {
      return false;
    }
    return blankEngine || blankRoad;
  };

  const tellValidity = (changedProp, changedValue) => {
    const { name, setRoadNameNumberComboBoxValidity } = props;
    if (setRoadNameNumberComboBoxValidity) {
      setRoadNameNumberComboBoxValidity(name, isValid(changedProp, changedValue));
    }
  };

  const isValid = (changedProp, changedValue) => {
    let { engine, road } = state;
    if (changedProp === 'road') {
      road = changedValue;
    }
    if (changedProp === 'engine') {
      engine = changedValue;
    }
    const val = engine !== null && road !== null && engine !== '' && road !== '';
    return val;
  };

  const { road, engine, possibleEngines } = state;
  const nv = `engine-${name}`;
  const idv = `engine-${name}`;
  const idvList = `engine-datalist${name}`;

  const dlRows = [];
  if (possibleEngines && possibleEngines.length > 0) {
    possibleEngines.forEach((item) => {
      const { id, num } = item;
      dlRows.push(
        <option key={id} value={num}>
          {num}
        </option>
      );
    });
  }
  const validEngine = !engineData ? false : Utils.validEngine(engineData.road, engineData.num);
  // if (validEngine) {
  //   // tellValidity(name, true);
  // }
  let validClass = isValid() || validEngine ? 'valid' : 'invalid';
  if (!testValidity) {
    validClass = '';
  }

  const selectValue = `${engineData && engineData.road ? engineData.road : road}`;

  return (
    <div
      className={`road-name-number-combo-box ${blank() ? 'road-name-number-combo-box--blank ' : ''}
                  road-name-number-combo-box--${validClass}`}
    >
      <select
        className=""
        id={`road-${name}`}
        name={`road-${name}`}
        value={selectValue}
        required
        onChange={(e) => {
          setRoad(e.currentTarget.value);
          if (additionalOnChange) {
            additionalOnChange(e.currentTarget.value, 'road', name);
          }
        }}
      >
        <option value="">-</option>
        {Object.keys(roads).map((title) => {
          return (
            <option value={roads[title].id} key={title}>
              {roads[title].short_name}
            </option>
          );
        })}
      </select>
      <input
        onChange={(e) => {
          setEngine(e.currentTarget.value);
          if (additionalOnChange) {
            additionalOnChange(e.currentTarget.value, 'engine', name);
          }
        }}
        type="number"
        min="0"
        max="9999"
        className="RoadNameNumberComboBox__input"
        maxLength="4"
        inputMode="tel"
        value={`${engineData && engineData.num ? engineData.num : engine}`}
        required
        list={idvList}
        name={nv}
        pattern="[0-9]{0,4}"
        id={idv}
      />
      <datalist id={idvList}>{dlRows}</datalist>
    </div>
  );
};

RoadNameNumberComboBox.defaultProps = {
  name: null,
  engines: null,
  roads: null,
  setRoadNameNumberComboBoxValidity: null,
  additionalOnChange: null,
  testValidity: true,
  engineData: null,
};

RoadNameNumberComboBox.propTypes = {
  name: PropTypes.string,
  engineData: PropTypes.object,
  testValidity: PropTypes.bool,
  additionalOnChange: PropTypes.func,
  setRoadNameNumberComboBoxValidity: PropTypes.func,
  roads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  engines: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default RoadNameNumberComboBox;
