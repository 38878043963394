import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { encode } from 'blurhash';
import { Blurhash } from 'react-blurhash';
import Utils from './Utils';
import { createMakeImageHashEvent, createSaveImageHashEvent } from '../actions';
import IEngine from '../interfaces/IEngine';
import IAppState from '../interfaces/IAppState';

type TrainPictureProps = {
  filename: string;
  roadNames: Array<Object>;
  imageId: string;
  removeImage: Function;
  enlargeImage: Function;
  sized: boolean;
  engineData: Array<any>;
  timestamp: string;
  activeEngineId: any;
  enginesInPic: Array<object>;
  rotated: boolean;
  fullWidth: boolean;
  justHash: boolean;
  blurhash: string;
  index: number;
  id: number;
};
type TrainPictureState = {
  rotDir: string;
  error: boolean;
  checkSized: boolean;
  srcSet: string;
  justNowSized: boolean;
  loading: boolean;
  cacheBust: boolean,
  rotated: boolean,
};

const TrainPicture: React.FC<TrainPictureProps> = (props) => {
  const dispatch = useDispatch();
  const roadNames = useSelector((state: IAppState) => state.roads);
  const settings = useSelector((state: IAppState) => state.settings);
  const roads = useSelector((state: IAppState) => state.roads);

  const {
    engineData,
    enlargeImage,
    imageId,
    timestamp,
    activeEngineId,
    enginesInPic,
    fullWidth,
    filename,
    rotated,
    blurhash,
    justHash,
  } = props;

  const [state, setState] = useState({
    rotDir: '',
    error: false,
    checkSized: false,
    srcSet: Utils.getSrcSet({ filename }),
    justNowSized: false,
    loading: false,
    cacheBust: false,
  });

  function sizeMe() {
    const t = this;
    const oReq = new XMLHttpRequest();
    const { rotDir } = state;
    const { imageId, filename } = props;
    oReq.open('POST', '/size_image.php', true);
    oReq.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    oReq.onload = () => {
      if (oReq.status === 200) {
        t.setState({
          justNowSized: true,
          srcSet: Utils.getSrcSet({ filename, breakCache: true }),
        });
      } else {
        console.log(`Error ${oReq.status} occurred when trying to upload your file.`);
      }
    };
    oReq.send(`rot_num=${rotDir}&image_id=${imageId}&filename=${filename.replace('uploads/', '')}`);
    return false;
  }

  function rotateAndSizeDone(oEvent) {
    const oReq = oEvent.currentTarget;
    if (oReq.status === 200 && oReq.readyState === 4) {
      const { filename } = props;
      console.log('we have rotated and sized');
      const newState: TrainPictureState = {
        ...state,
        justNowSized: true,
        loading: false,
        cacheBust: true,
        rotated: true,
        error: false,
        srcSet: Utils.getSrcSet({ filename, breakCache: true }),
      };
      setState(newState);
    } else if(oReq.readyState === 4) {
      console.log(`Error ${oReq.status} occurred when trying to upload your file.`);
    }
  }

  function readExif() {
    const { imageId, filename } = props;
    const { rotDir, error } = state;
    const imagename = filename.replace('uploads/', '');
    const oReq = new XMLHttpRequest();
    oReq.addEventListener('load', rotateAndSizeDone);
    try {
      oReq.upload.addEventListener('load', rotateAndSizeDone);
    } catch (e) {
      console.log('no upload?');
    }
    oReq.open('POST', '/api/rotate-and-size-image', true);
    oReq.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    oReq.send(`rot_num=${rotDir}&image_id=${imageId}&filename=${imagename}&error=${error}`);
    setState({
      ...state,
      loading: true,
    });
    return false;
  }

  function deleteMe() {
    const { imageId, removeImage } = props;
    const oReq = new XMLHttpRequest();
    oReq.open('POST', '/delete_image.php', true);
    oReq.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    oReq.onload = () => {
      if (oReq.status === 200) {
        removeImage(imageId);
      } else {
        console.log(`Error ${oReq.status} occurred when trying to upload your file.`);
      }
    };
    oReq.send(`image_id=${imageId}`);
    return false;
  }

  function dealWith() {
    const { checkSized } = state;
    const { filename, sized, imageId } = props;
    console.log(`dealWith-${imageId}:${checkSized} ${sized}`);
    if (!checkSized) {
      const srcSet = Utils.getSrcSet({
        errored: true,
        filename,
        sized,
      });
      setState({
        ...state,
        error: true,
        checkSized: true,
        srcSet,
      });
    } else {
      setState({
        ...state,
        error: true,
      });
    }
  }

  let d: any = '';
  let exif: any = '';
  const {
    loading, error, justNowSized, cacheBust, srcSet
  } = state;

  if (!rotated && !loading) {
    exif = (
      <div className="train-picture__upper-right">
        <div className="train-picture__upper-right-inner">
        {!justNowSized && !rotated && (
          <>
          <button type="button" className="exif" onClick={readExif}>
            Rotate & Size
          </button>
          <br />
          <input
            type="text"
            name="rot-dir"
            onChange={(e) => {
              setState({
                ...state,
                rotDir: e.currentTarget.value,
              });
            }}
          />
          </>
        )}
        </div>
      </div>
    );
  } else if (loading) {
    exif = <span>loading...</span>;
  }

  if (error) {
    d = (
      <button type="button" onClick={deleteMe}>
        Delete
      </button>
    );
  }
  let ts = new Date();

  const toLoop = enginesInPic;
  const pieces = timestamp.toString().split('T');
  const datePieces = pieces[0].split('-');
  ts.setUTCFullYear(parseInt(datePieces[0]));
  ts.setUTCMonth(parseInt(datePieces[1]) - 1);
  ts.setUTCDate(parseInt(datePieces[2]));
  const timePieces = pieces[1].split(':');
  ts.setUTCHours(parseInt(timePieces[0]));
  ts.setUTCMinutes(parseInt(timePieces[1]));
  ts.setUTCSeconds(parseInt(timePieces[2]));
  const dateTimestamp = new Date(timestamp.toString());
  const minDiff = dateTimestamp.getTimezoneOffset() - ts.getTimezoneOffset();
  const diff = minDiff * 60 * 1000;
  ts = new Date(ts.getTime() - diff);
  const alt = `Train ID: ${filename}`;
  let classes = `train-picture train-picture--thumb ${fullWidth ? 'train-picture--full-width' : ''}`;
  if (activeEngineId) {
    enginesInPic.forEach((e:IEngine) => {
      if (e.id === activeEngineId) {
        classes += ` train-picture--active train-picture--active-${roads.filter(r => r.id === e.road)[0].short_name.toLowerCase()}`;
      }
    });
  }
  const sizes = fullWidth ? '(min-width: 1080px) 1080px, 100vw' : '(min-width: 1080px) 220px, 20vw';

  const loadImage = async () =>
    new Promise((resolve, reject) => {
      try {
        const img = new Image();
        const { imageData } = settings;
        if (settings.id) {
          img.onload = () => resolve(img);
          img.onerror = (e) => {
            try {
              // dis
            } catch (e) {
              return reject(e);
            }
          };
          img.src = `data:image/jpeg;base64,${btoa(imageData.data)}`;
        }
      } catch (e) {
        reject(e);
      }
    });

  useEffect(() => {
    if (settings && settings.imageData && settings.imageData.data && settings.id === parseInt(imageId, 10)) {
      loadImage().then((res2: CanvasImageSource) => {
        const canvas = document.createElement('canvas');
        canvas.width = settings.imageData.width;
        canvas.height = settings.imageData.height;
        const context = canvas.getContext('2d');
        context.drawImage(res2, 0, 0);
        const ret = context.getImageData(0, 0, settings.imageData.width, settings.imageData.height);
        console.log('attempt to encode')
        const finalRes = encode(ret.data, settings.imageData.width, settings.imageData.height, 4, 4);
        dispatch(createSaveImageHashEvent(imageId, finalRes));
      }).catch((e) => {

      });
    }
  }, [settings]);

  const makeBlurHash = async (e) => {
    e.preventDefault();
    dispatch(createMakeImageHashEvent(imageId));
    return false;
  };

  const imgRef = useRef();

  useEffect(() => {
    if (imgRef && imgRef?.current) {
      if (justHash) {
        imgRef?.current?.classList?.remove('train-picture__img--loaded');
      } else {
        imgRef?.current?.classList?.add('train-picture__img--loaded');
      }
    }
  }, [justHash]);
  return (
    <div data-id={imageId} className={classes}>
      {blurhash && (
        <Blurhash
          hash={blurhash}
          width={500}
          height={187.5 * 2}
          resolutionX={16}
          resolutionY={16}
          punch={1}
          className="train-picture__blurhash-thumb"
          style={{
            width: 'calc(100% - 10px)',
            height: 'calc(100% - 10px)',
            top: '5px',
            left: '5px',
          }}
        />
      )}
      <h2 className="offscreen">
        {ts.toLocaleDateString()} {ts.toLocaleTimeString()}
      </h2>
      <div className="train-picture__img-wrap">
        <img
          ref={imgRef}
          className="train-picture__img"
          onLoad={(e) => {
            e.currentTarget.classList.add('train-picture__img--loaded');
            e.currentTarget.classList.add('train-picture__img--finished');
          }}
          sizes={sizes}
          srcSet={srcSet}
          load="lazy"
          alt={alt}
          onError={dealWith}
        />
        {d}
        {exif}
        {!fullWidth && (
          <button
            type="button"
            onClick={() => {
              enlargeImage(imageId);
            }}
            className="train-picture__enlarge"
          >
            Enlarge
          </button>
        )}
        {!blurhash && rotated && (
          <button type="button" className="train-picture__hash-button" onClick={makeBlurHash}>
            Hash Image
          </button>
        )}
        <div className="train-picture__engines">
          {toLoop.map((e: IEngine) => {
            const filtered = roadNames.filter(road => road.id === e.road)[0];
            if (e.id) {
              return (
                <div className="train-picture__engine" key={e.id}>
                  {filtered && filtered.short_name}-{e.num}
                </div>
              );
            }
            return engineData
              .filter(engine => engine.id === e)
              .map(e2 => (
                <div className="train-pictures__engine" key={e2.id}>
                  {roadNames[e2.road]}-{e2.number}
                </div>
              ));
          })}
        </div>
      </div>
    </div>
  );
};

TrainPicture.defaultProps = {
  removeImage: null,
  activeEngineId: null,
  enginesInPic: null,
  enlargeImage: null,
  justHash: false,
  engineData: null,
  id: null,
  fullWidth: false,
  sized: false,
  index: null,
  blurhash: null,
};

TrainPicture.propTypes = {
  id: PropTypes.number,
  blurhash: PropTypes.string,
  fullWidth: PropTypes.bool,
  sized: PropTypes.bool,
  justHash: PropTypes.bool,
  rotated: PropTypes.bool.isRequired,
  imageId: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  enlargeImage: PropTypes.func,
  engineData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  removeImage: PropTypes.func,
  activeEngineId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  enginesInPic: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  index: PropTypes.number,
};
export default TrainPicture;
