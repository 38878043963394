import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import IAppState from 'interfaces/IAppState';
import IRoad from 'interfaces/IRoad';
import IEngine from 'interfaces/IEngine';
import { getNewestEngines } from '../actions';
import EngineTag from './EngineTag';

const NewestEngines: React.FC = () => {
  const dispatch = useDispatch();
  const roads: Array<IRoad> = useSelector((state: IAppState) => state.roads);
  const data: Array<IEngine> = useSelector((state: IAppState) => state.engines.newestEngines);
  if (!data || data.length === 0) {
    dispatch(getNewestEngines());
  } else {
    console.dir('data');
  }
  return (
    <div className="engine-listing__list">
      <h2 className="full">Newest Engines:</h2>
      <ul className="plain-list plain-list--stack">
        {roads &&
          data &&
          data.map(({ road, num }) => {
            let roadV = roads[road];
            if (!roadV) {
              roadV = roads.filter((r) => r.id === road);
            }
            const ret = road ? (
              <li key={`${road}${num}`} className="single-engine--in-list">
                <EngineTag road={roads.filter((roadParam) => roadParam.id === road)[0].short_name} num={num} short />
              </li>
            ) : null;
            return ret;
          })}
      </ul>
    </div>
  );
};

NewestEngines.defaultProps = {};

NewestEngines.propTypes = {};

export default NewestEngines;
