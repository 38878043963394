import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MainMenu from './MainMenu';
import HomePage from './HomePage';
import AddImage from './AddImage';
import AddManualSighting from './AddManualSighting';
import FindEngine from './FindEngine';
import ViewEngine from './ViewEngine';
import ViewEnginePhotos from './ViewEnginePhotos';
import ViewSightings from './ViewSightings';
import ViewSighting from './ViewSighting';
import EngineListing from './EngineListing';
import ViewLocations from './ViewLocations';
import ViewLocation from './ViewLocation';
import Stats from './Stats';

/*
  features notes:
  view engine - date is wrong on sighting (8327)
  1) pretty up previous locations?
  2) enlarge other photos of engine?
  3) ViewEngine background of random sighting?
  more logging on upload

  befre 372, after 285
  specialties?
    power move
    heritage
    high hood
    rear-end forward

*/

const fakeAuth = {
  isAuthenticated: false,
  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100); // fake async
  },
};

// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route {...rest} render={(props) => (
//     fakeAuth.isAuthenticated === true
//       ? <Component {...props} />
//       : <Redirect to='/login' />
//   )} />
// )
// <PrivateRoute path='/protected' component={Protected} />

class Root extends Component {
  getSightingById(id) {
    const { sightings } = this.props;
    const sighting = sightings.filter((sightingLoop) => id === sightingLoop.id);
    return sighting.length > 0 ? sighting[0] : null;
  }

  getLocationById(id) {
    const { locations, sightings } = this.props;
    return locations && sightings && sightings[id] && locations[sightings[id].location]
      ? locations[sightings[id].location]
      : null;
  }

  render() {
    const {
      addEngines,
      engines,
      removeImageFromSighting,
      totalSightings,
      totalSightingsByLocation,
      className,
      roads,
    } = this.props;

    return (
      <BrowserRouter>
        <div className={`color-mode ${className}`}>
          <h1 style={{fontSize:'10px',fontWeight:'400',margin: '0', borderBottomLeftRadius: '5px', zIndex: '100', position: 'fixed', padding: '8px', top:'0', right:'0', background: 'rgba(255, 255, 255, .75'}}>
            {process.env.NODE_ENV}
            {' '}
            {process.env.REACT_APP_BUCKET_NAME}
            {' '}
            {process.env.REACT_APP_BUCKET_NAME}
          </h1>
          <MainMenu />
          <main>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/add-image" render={() => <AddImage roads={roads} engines={engines} />} />
              <Route path="/add-manual-sighting" component={AddManualSighting} />
              <Route path="/stats" component={Stats} />
              <Route path="/find-engine" render={() => <FindEngine roads={roads} />} />
              <Route
                path="/view-location/:locationSlug"
                render={(props) => <ViewLocation locationSlug={props.match.params.locationSlug} />}
              />
              <Route path="/view-locations" render={() => <ViewLocations />} />
              <Route
                path="/view-sightings/:locationSlug?/:page?"
                render={(props) => (
                  <ViewSightings
                    totalSightings={totalSightings}
                    page={props.match.params.page}
                    locationSlug={props.match.params.locationSlug}
                    totalSightingsByLocation={totalSightingsByLocation}
                  />
                )}
              />
              <Route
                path="/view-sighting/:sightingId"
                render={(props) => (
                  <ViewSighting
                    location={this.getLocationById(props.match.params.sightingId)}
                    sightingId={props.match.params.sightingId}
                    removeImageFromSighting={removeImageFromSighting}
                  />
                )}
              />
              <Route
                path="/view-engines/:road?/:page?"
                render={(props) => (
                  <EngineListing
                    road={props.match.params.road}
                    page={props.match.params.page}
                    addEngines={addEngines}
                  />
                )}
              />

              <Route
                path="/view-engine/:road/:engine/photos"
                render={(props) => (
                  <ViewEnginePhotos
                    addEngines={addEngines}
                    road={props.match.params.road}
                    engine={props.match.params.engine}
                  />
                )}
              />
              <Route
                path="/view-engine/:road/:engine"
                render={(props) => (
                  <ViewEngine
                    addEngines={addEngines}
                    road={props.match.params.road}
                    engine={props.match.params.engine}
                  />
                )}
              />
            </Switch>
          </main>
        </div>
      </BrowserRouter>
    );
  }
}

Root.propTypes = {
  className: PropTypes.string.isRequired,
  addEngines: PropTypes.func.isRequired,
  engines: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  locations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  removeImageFromSighting: PropTypes.func.isRequired,
  roads: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  sightings: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  totalSightings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  totalSightingsByLocation: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

const mapStateToProps = (state) => {
  const { locations, roads } = state;
  return {
    locations,
    roads,
  };
};

export default connect(
  mapStateToProps,
  {},
)(Root);
