import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

class NumberAnimation extends React.Component {
  // todo - add zero spacers
  static AnimationTime = 50;

  constructor(props) {
    super(props);
    const { end, start } = this.props;
    this.state = {
      end,
      currentNumber: start,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      end: props.end,
      start: props.start,
      currentNumber: state.currentNumber,
      animate: !Number.isNaN(state.end) && !Number.isNaN(props.end),
    };
  }

  render() {
    const { end, animate, currentNumber } = this.state;

    const placeholder =
      end
        .toString()
        .split('')
        .map(() => '0') || '0';

    if (animate) {
      const newCurrentNumber = Math.min(end, currentNumber + 2);
      setTimeout(() => {
        if (currentNumber < end - 1) {
          this.setState({
            currentNumber: newCurrentNumber,
          });
        }
      }, NumberAnimation.AnimationTime);
    }

    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        {animate ? (
          <>
            <CSSTransition appear in timeout={0} unmountOnExit classNames="fade-out">
              <div className="number">{Math.max(0, currentNumber - 1)}</div>
            </CSSTransition>
            <div className="number" aria-hidden="true" style={{ opacity: '0', position: 'relative' }}>
              {end}
            </div>
            <CSSTransition appear timeout={0} in classNames="fade-in">
              <div className="number"> {currentNumber === end ? currentNumber : currentNumber + 1}</div>
            </CSSTransition>
          </>
        ) : (
          <div className="number" style={{ position: 'relative' }}>
            {placeholder}
          </div>
        )}
        <style jsx global>{`
          .number {
            letter-spacing: -4px;
            font-size: 6.75rem;
            transition: opacity ${NumberAnimation.AnimationTime}ms ease-out;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: right;
          }
          .fade-out-appear-active {
            opacity: 1;
          }
          .fade-out-appear-done {
            opacity: 0;
          }
          .fade-in-appear-active {
            opacity: 0;
          }
          .fade-in-appear-done {
            opacity: 1;
          }
        `}</style>
      </div>
    );
  }
}

NumberAnimation.defaultProps = {
  start: 0,
  end: NaN,
};

NumberAnimation.propTypes = {
  end: PropTypes.number,
  start: PropTypes.number,
};

export default NumberAnimation;
