import { combineReducers } from 'redux';
import locationsReducer from './locationsReducer';
import sightingsReducer from './sightingsReducer';
import enginesReducer from './enginesReducer';
import searchReducer from './searchReducer';
import settingsReducer from './settingsReducer';
import roadsReducer from './roadsReducer';
import statsReducer from './statsReducer';

export default combineReducers({
  locations: locationsReducer,
  sightings: sightingsReducer,
  engines: enginesReducer,
  searchResults: searchReducer,
  settings: settingsReducer,
  roads: roadsReducer,
  stats: statsReducer,
});
