import React from 'react';
import PropTypes from 'prop-types'; // ES6
import Utils from './Utils';

type SightingTimeProps = {
  avgTOS?: number;
  totalTime?: number;
  length?: number;
  string?: string;
  showDayOfWeek?: boolean;
  newStyle?: boolean;
  id: number | string;
};
const SightingTime: React.FC<SightingTimeProps> = (props) => {
  const { avgTOS, showDayOfWeek, totalTime, length, string, newStyle = false, id } = props;
  const diff = avgTOS - new Date().getTimezoneOffset();
  const avg = totalTime / length;
  const t = new Date();
  t.setTime(avg - diff * 60 * 1000);
  let timeToSet = null;
  if (string && string !== '0000-00-00 00:00:00' && string != 'hi') {
    let parsed: number = Date.parse(string);
    if (Number.isNaN(parsed)) {

      const exploded = string.split(' ');
      const datePiece = exploded[0].split('-'); // [0]);
      const timePiece = exploded[1].split(':');
      parsed = new Date(
        parseInt(datePiece[0], 10),
        parseInt(datePiece[1], 10) - 1,
        parseInt(datePiece[2], 10),
        parseInt(timePiece[0], 10),
        parseInt(timePiece[1], 10),
        parseInt(timePiece[2], 10)
      ).getTime();

    }
    const tzoffset = new Date(parsed).getTimezoneOffset();
    const time = parsed - tzoffset * 60 * 1000;
    const d = new Date();
    d.setTime(time);
    timeToSet = time;
  } else {
    timeToSet = avg - t.getTimezoneOffset() * 60 * 1000;
  }

  const t2 = new Date();
  t2.setTime(timeToSet);

  let timeString = '';
  if (t2.toLocaleDateString()) {
    timeString += t2.toLocaleDateString();
  }
  timeString += ' ';
  if (t2.toLocaleTimeString()) {
    timeString += t2.toLocaleTimeString();
  }
  const dbts = new Date();
  dbts.setTime(t2.getTime());
  const val = `${dbts.getUTCFullYear()}-${1 +
    dbts.getUTCMonth()}-${dbts.getUTCDate()} ${dbts.getUTCHours()}:${dbts.getUTCMinutes()}:${dbts.getUTCSeconds()}`;
  if (!string) {
    window.tsString = val;
    window.stamp = t2.getTime();
  }
  const dayOfWeek = `${Utils.dayOfWeek(t2.getDay())}, `;
  return (
    <>
    <style jsx global>
        {`
          .vert-cent{
            display:flex;
            align-items:center;
          }
            .pill {
              font-family: 'Roboto', sans-serif;
              background-color:#001E3C;
              color:white;
              border-radius:2px;
              font-size:.875rem;
              display:inline-block;
              font-weight:500;
              padding:2px 4px;
              margin:0 5px;
            }
          `}
      </style>

    <time

      id={id ? id.toString() : null}
      dateTime={`${t2.getUTCFullYear()}-${t2.getUTCMonth() < 9 ? '0' : ''}${1 +
        t2.getUTCMonth()}-${
        t2.getUTCDate() < 9 ? '0' : ''
      }${t2.getUTCDate()}T${dbts.getUTCHours()}:${dbts.getUTCMinutes()}:${dbts.getUTCSeconds()}-0${(
        t2.getTimezoneOffset() / 60
      ).toString()}00`}
    >
      {showDayOfWeek && dayOfWeek}
      {newStyle ? (
        <span className="vert-cent">
          <span className="pill">{`${timeString.split(':')[0].split(' ')[0]}`}</span>
          <span className="pill">{timeString.split(' ')[1].split(':')[0]}:{`${timeString.split(':')[1]}${timeString.split(':')[2].split(' ')[1]}`}</span>
        </span>
      ) : (
        timeString
      )}
    </time>
    </>
  );
};

SightingTime.defaultProps = {
  avgTOS: null,
  totalTime: null,
  length: null,
  string: null,
  newStyle: false,
  showDayOfWeek: false,
};

SightingTime.propTypes = {
  avgTOS: PropTypes.number,
  totalTime: PropTypes.number,
  length: PropTypes.number,
  string: PropTypes.string,
  showDayOfWeek: PropTypes.bool,
};

export default SightingTime;
